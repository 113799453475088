import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMe } from '../../hooks/useMe';
import { useClients } from '../../hooks/useClients';
import { userIsAdmin } from '../../utils/misc';
import { PageHeader, ProfileCard, FormToggle } from '../../components';
import { AnimatePresence } from 'framer-motion';

import './MyClients.scss';

const MyClients = (props) => {
	const user = useMe();
	const isAdmin = userIsAdmin(user.roles);
	const { data: clients } = useClients();
	const [myClientsOnly, setMyClientsOnly] = useState(!isAdmin);

	const toggleChangeHandler = (ev) => {
		setMyClientsOnly(ev.target.checked);
	};

	const computedClientCountString = (clientsArray) => {
		const clientCount = myClientsOnly ? user.clients.length : clientsArray.length;

		return `${clientCount} client${clientCount !== 1 ? 's' : ''}`;
	};

	return (
		<div className="MyClients">
			<PageHeader
				title={isAdmin ? 'Clients' : 'My Clients'}
				icon="user-group"
				subhead={clients && computedClientCountString(clients)}>
				<Link className="Button" to="/my-clients/new">
					New Client
				</Link>
			</PageHeader>

			<div className="wrapper">
				{isAdmin && (
					<h5 className="MyClients__admin-toggle">
						<span>View only my clients</span>
						<FormToggle id="myClientsOnlyToggle" onChange={toggleChangeHandler} />
					</h5>
				)}
				<ul className="MyClients__grid">
					<AnimatePresence>
						{clients &&
							(isAdmin ? clients : user.clients).map(
								(client, idx) =>
									(myClientsOnly ? user.clients.findIndex((c) => c.id === client.id) > -1 : true) && (
										<ProfileCard
											as="li"
											key={`client-profile-${idx}`}
											userProfile={client.profile}
											link={`/my-clients/${client.id}`}
											healthEducator={(client.healthEducators ? client.healthEducators[0] : null)}
										/>
									)
							)}
					</AnimatePresence>
				</ul>
			</div>
		</div>
	);
};

export default MyClients;
