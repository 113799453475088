import { Icon, ProgressBar } from '..';
import { unixTimestamp2TimeAgo } from '../../utils/misc';

import './MiniProgressCard.scss';

const MiniProgressCard = ({ lessonInProgress, iconName = 'module' }) => {
	return (
		<div className="MiniProgressCard">
			<ProgressBar
				clientData={lessonInProgress.clientData}
				progressPercentage={lessonInProgress.progressPercentage}
				completeTime={lessonInProgress.complete ? unixTimestamp2TimeAgo(lessonInProgress.complete) : false}
			/>
			<h5 className="MiniProgressCard__module-title">{lessonInProgress.moduleTitle}</h5>
			<h3 className="MiniProgressCard__lesson-title">{lessonInProgress.title}</h3>
			<Icon name={iconName} className="secondary" />
		</div>
	);
};

export default MiniProgressCard;
