import React from 'react';
import { Link } from 'react-router-dom';
import { useMe } from '../../hooks/useMe';
import { useCourse } from '../../hooks/useCourse';
import { Icon, PageHeader, ColumnCard } from '../../components';

import './LessonIntro.scss';

const LessonIntro = (props) => {
	const user = useMe();
	const { isLoading, error, data: course } = useCourse();

	if (isLoading) return <p>loading...</p>;

	if (error) return <p>{error.message}</p>;

	const lesson = course.modules
		.find((m) => m.slug === props.match.params.slug)
		.lessons.find((lesson) => lesson.slug === props.match.params.lessonSlug);

	console.log(lesson);

	return (
		<div className="LessonIntro">
			<PageHeader />
			<div className="wrapper">
				<ColumnCard
					image={
						lesson.metadata.featured_image
							? lesson.metadata.featured_image
							: lesson.metadata.lesson_intro.lesson_intro_participant_content.image
							? lesson.metadata.lesson_intro.lesson_intro_participant_content.image.sizes.large
							: null
					}
					col2Content={
						<>
							<h2>{lesson.title}</h2>
							<div
								className="wysiwyg"
								dangerouslySetInnerHTML={{
									__html: lesson.metadata.lesson_intro.lesson_intro_participant_content.text,
								}}></div>
							{lesson.metadata.sections && (
								<Link
									className="Button"
									to={`/modules/${props.match.params.slug}/${props.match.params.lessonSlug}/${lesson.metadata.sections[0].slug}`}>
									Start Lesson
								</Link>
							)}
						</>
					}
					withPadding
				/>

				{user.roles.indexOf('participant') < 0 && (
					<div className="he he--intro">
						<h3 className="he__title">
							<Icon name="user"></Icon>
							<span>Health Educator's Section</span>
						</h3>
						<div
							className="he__summary"
							dangerouslySetInnerHTML={{
								__html: lesson.metadata.lesson_intro.lesson_intro_he_content.lesson_intro_he_content_summary,
							}}
						/>
						<ul className="LessonIntro__columns">
							<li>
								<h5 className="block__title">Materials Needed</h5>
								<div
									className="wysiwyg"
									dangerouslySetInnerHTML={{
										__html: lesson.metadata.lesson_intro.lesson_intro_he_content
											.lesson_intro_he_content_materials_needed,
									}}></div>
							</li>
							<li>
								<h5 className="block__title">Objectives</h5>
								<div
									className="wysiwyg"
									dangerouslySetInnerHTML={{
										__html: lesson.metadata.lesson_intro.lesson_intro_he_content.lesson_intro_he_content_objectives,
									}}></div>
							</li>
						</ul>

						{lesson.metadata.lesson_intro.lesson_intro_he_content.lesson_intro_he_content_teaching_points && (
							<div className="he__block block block--icon">
								<h5 className="block__title">
									<span className="icon btn--icon bg-green color-white">
										<Icon name="health-educators" />
									</span>
									Teaching Points
								</h5>
								<div
									className="wysiwyg"
									dangerouslySetInnerHTML={{
										__html: lesson.metadata.lesson_intro.lesson_intro_he_content
											.lesson_intro_he_content_teaching_points,
									}}
								/>
							</div>
						)}

						<ul className="LessonIntro__columns">
							{lesson.metadata.lesson_intro.lesson_intro_he_content.lesson_intro_he_content_activities && (
								<li>
									<h5 className="block__title">Activities</h5>
									<div
										className="wysiwyg"
										dangerouslySetInnerHTML={{
											__html: lesson.metadata.lesson_intro.lesson_intro_he_content.lesson_intro_he_content_activities,
										}}
									/>
								</li>
							)}
							{lesson.metadata.lesson_intro.lesson_intro_he_content.lesson_intro_he_content_reference_manuals && (
								<li>
									<h5 className="block__title">Reference Manual</h5>
									<div
										className="wysiwyg"
										dangerouslySetInnerHTML={{
											__html: lesson.metadata.lesson_intro.lesson_intro_he_content
												.lesson_intro_he_content_reference_manuals,
										}}
									/>
								</li>
							)}
							{lesson.metadata.lesson_intro.lesson_intro_he_content.lesson_intro_he_content_wrap_up && (
								<li>
									<h5 className="block__title">Wrap Up</h5>
									<div
										className="wysiwyg"
										dangerouslySetInnerHTML={{
											__html: lesson.metadata.lesson_intro.lesson_intro_he_content.lesson_intro_he_content_wrap_up,
										}}
									/>
								</li>
							)}
						</ul>

						<Link
							className="btn"
							to={`/modules/${props.match.params.slug}/${props.match.params.lessonSlug}/${lesson.metadata.sections[0].slug}`}>
							Start Lesson
						</Link>
					</div>
				)}
			</div>
		</div>
	);
};

export default LessonIntro;
