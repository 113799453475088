import { useMutation, useQueryClient } from 'react-query';
import localforage from 'localforage';
import axios from '../utils/api';
import { useViewAs } from './useViewAs';

export const useUpdateProfile = () => {
	const queryClient = useQueryClient();
	const { viewAsUser } = useViewAs();

	const mutationFn = ({ user, fields }) => {
		for (const fieldName in fields) {
			if (fields.hasOwnProperty(fieldName) && user.profile.hasOwnProperty(fieldName) && fieldName !== 'portrait')
				user.profile[fieldName] = fields[fieldName];
		}

		if ('firstname' in fields || 'lastname' in fields) user.profile.fullname = user.profile.firstname + ' ' + user.profile.lastname;

		user.lastUpdated = Math.floor(Date.now() / 1000);

		if (fields.hasOwnProperty('date_of_birth')) fields['date_of_birth'] = fields['date_of_birth'].replace(/-/g, '');

		if (viewAsUser) queryClient.invalidateQueries('clients');

		return navigator.onLine
			? axios
					.post(`/wp-json/wp/v2/user/${user.id}/update`, { lastUpdated: user.lastUpdated, fields: fields })
					.then(({ data: updatedUser }) => localforage.setItem('user', updatedUser))
			: localforage.setItem('user', user);
	};

	return useMutation(mutationFn, {
		onSuccess: (data) => {
			queryClient.invalidateQueries('user');
		},
		onError: (error) => {
			error.response && console.warn('error in mutation: ', error.response.data.message);
		},
	});
};
