import localforage from 'localforage';
import { useState } from 'react';
import { PageHeader } from '../../components';
import { HealthEducatorSection } from './HealthEducatorSection';
import { ParticipantSection } from './ParticipantSection';

import './Dashboard.scss';
import { useEffect } from 'react';

const Dashboard = ({ currentUser }) => {
	const [localUser, setLocalUser] = useState(false);

	useEffect(() => {
		localforage.getItem('user').then((u) => {
			setLocalUser(u);
		});
	}, []);

	if (!localUser) return 'Loading...';

	return (
		<div className="Dashboard">
			<PageHeader title="My Dashboard" icon="home"></PageHeader>

			{currentUser.roles.indexOf('participant') < 0 && <HealthEducatorSection user={currentUser} />}

			{currentUser.roles.indexOf('participant') > -1 && <ParticipantSection user={currentUser} />}
		</div>
	);
};

export default Dashboard;
