import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { ColumnCard, PageHeader, MiniProgressCard, Button } from '../../components';
import axios from '../../utils/api';
import { useClient } from '../../hooks/useClient';
import { getClientCardContent } from '../../utils/misc';

import './Client.scss';

const Client = (props) => {
	const queryClient = useQueryClient();
	const [resetInProgress, setResetInProgress] = useState(false);
	const { isLoading, error, data: client } = useClient(+props.match.params.clientId);

	// console.log(client);

	if (isLoading) return 'Loading...';

	if (error) return 'error: ' + error.message;

	const clientCardContent = getClientCardContent(client);

	const resetClientProgress = () => {
		const resetProgressObject = { lastUpdated: Math.floor(Date.now() / 1000), lessons: [] };

		setResetInProgress(true);

		axios
			.post(`/wp-json/wp/v2/user/${+props.match.params.clientId}/update`, {
				lastUpdated: resetProgressObject.lastUpdated,
				fields: { progress: resetProgressObject },
			})
			.then(({ data: updatedClient }) => {
				queryClient.invalidateQueries('clients');
				queryClient.invalidateQueries(['client', +props.match.params.clientId]);

				setResetInProgress(false);
			});
	};

	return (
		<div className="Client">
			<PageHeader title="Client Information" icon="user-group" currentClient={client}>
				<div className="container--btn">
					{window.location.hostname.indexOf('localhost') > -1 && (
						<Button
							disabled={resetInProgress}
							btnStyle="danger"
							onClick={() => {
								resetClientProgress();
							}}>
							{resetInProgress ? 'Resetting...' : 'Reset client progress'}
						</Button>
					)}
					<Link className="Button Button--secondary" to={`/my-clients/${+props.match.params.clientId}/edit`}>
						Edit Client
					</Link>
				</div>
			</PageHeader>
			<div className="wrapper">
				{client && (
					<>
						<ColumnCard
							image={client.profile.portrait ? client.profile.portrait.url : null}
							col2Content={clientCardContent.col2Content}
							col3Content={clientCardContent.col3Content}
						/>

						{((client.progress && client.progress.lessons.length > 0) || client.assignedLessons.filter((l) => l.complete)) && (
							<>
								<h5 className="h5">Client Progress</h5>

								<div className="Client__progress-grid">
									{client.progress.lessons
										.sort((a, b) => (a.complete < b.complete ? 1 : -1))
										.map((lesson, idx) => (
											<MiniProgressCard key={`lesson-progress-${idx}-card`} lessonInProgress={lesson} />
										))}
								</div>
								{/* 
						<Cta icon="caret-down" clickHandler={() => console.log('click')} align="center">
							Load More
						</Cta> */}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default Client;
