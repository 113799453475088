import localforage from 'localforage';
import { useQuery } from 'react-query';
import axios from '../utils/api';

export const useClient = (clientId) =>
	useQuery(
		['client', clientId],
		() =>
			navigator.onLine
				? axios.get(`/wp-json/wp/v2/clients/${clientId}`).then((response) => response.data)
				: localforage.getItem('clients').then((clients) => clients.find((client) => client.id === clientId)),
		{ retry: false }
	);
