import axios from 'axios';
import localforage from 'localforage';

const instance = axios.create();

instance.interceptors.request.use(
	async (config) => {
		const token = await localforage.getItem('token');

		if (token) config.headers.Authorization = `Bearer ${token}`;

		return config;
	},
	(error) => Promise.reject(error)
);

instance.interceptors.response.use(
	(response) => {
		// console.log('intercepted response: ', response);
		return response;
	},
	(error) => {
		// if (error.response) {
		// 	console.log(error.response.data);
		// 	console.log(error.response.status);
		// 	console.log(error.response.headers);
		// } else if (error.request) {
		// 	console.log(error.request);
		// } else {
		// 	console.log('Error', error.message);
		// }

		// console.log(error.config);

		return Promise.reject(error);
	}
);

export default instance;
