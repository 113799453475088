import { useState, forwardRef } from 'react';
import { IconButton, DefaultPortrait } from '..';

import './ImageUpload.scss';

const ImageUpload = forwardRef(({ portraitSrc }, fileInputRef) => {
	const [portrait, setPortrait] = useState(portraitSrc);

	const imageUploadChangeHandler = (ev) => {
		const fileReader = new FileReader();

		if (fileInputRef.current.files[0]) {
			fileReader.readAsDataURL(fileInputRef.current.files[0]);

			fileReader.onload = (oFREvent) => setPortrait(oFREvent.target.result);
		}
	};

	return (
		<div className="ImageUpload">
			<input type="file" hidden onChange={imageUploadChangeHandler} ref={fileInputRef} />
			<IconButton
				icon="edit"
				className="ImageUpload__edit-btn bg--dark"
				clickHandler={() => {
					fileInputRef.current.click();
				}}
			/>
			{portrait ? <img src={portrait} alt="" /> : <DefaultPortrait />}
		</div>
	);
});

export default ImageUpload;
