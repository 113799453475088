import { useState, useEffect } from 'react';
import localforage from 'localforage';
import { useNormalizedCourse } from '../../hooks/useNormalizedCourse';
import { useCourse } from '../../hooks/useCourse';
import { getModulePreviewCardContent } from '../../utils/misc';
import { ColumnCard, PageHeader } from '../../components';
// import courseData from '../../assets/master-course.json';

import './Modules.scss';

const Modules = ({ currentUser }) => {
	const [user, setLocalUser] = useState(false);
	// const { data: normalizedCourse } = useNormalizedCourse();
	const { data: course } = useCourse();

	const [trainedModules, setTrainedModules] = useState([]);

	// const normalizedCourse = courseData;

	// console.log(normalizedCourse);

	// useEffect(() => {
	// 	if (!normalizedCourse) return;

	// 	console.log(normalizedCourse);
	// }, [normalizedCourse]);

	useEffect(() => {
		localforage.getItem('user').then((u) => {
			setLocalUser(u);
		});
	}, []);

	useEffect(() => {
		console.log("useeffect:",user);
		if(user && user.roles && user.roles.indexOf('participant') > -1){
			setTrainedModules(user.healthEducators[0].profile.trained_modules);
			
		}
		if(user && user.roles && user.roles.indexOf('participant') == -1){
			setTrainedModules(user.profile.trained_modules);
		}
	}, [user]);

	return (
		<div className="Modules">
			<PageHeader title="Modules" icon="book"></PageHeader>

			<div className="wrapper">
				{/* <ul className="Modules__ul">
					{normalizedCourse &&
						user.roles &&
						Object.keys(normalizedCourse.lookupTable).map((moduleId, idx) => {
							const module = normalizedCourse.modules[moduleId];
							const moduleLessons = normalizedCourse.lookupTable[moduleId].lessons.map(
								(lessonId) => normalizedCourse.lessons[lessonId]
							);
							const userLessons =
								user.roles.indexOf('participant') > -1 ? user.assignedLessons.filter((l) => l.active || l.complete) : false;
							const lessonsInProgress = user.roles.indexOf('participant') > -1 ? user.progress.lessons : false;
							const { col2Content, col3Content } = getModulePreviewCardContent(
								module,
								idx,
								userLessons,
								lessonsInProgress,
								moduleLessons
							);
							return (
								<li key={idx}>
									<ColumnCard
										image={module.metadata.featured_image}
										col2Content={col2Content}
										col3Content={col3Content}
										icon="book"
										withPadding
									/>
								</li>
							);
						})}
				</ul> */}
				{/* {isLoading && <p>Loading...</p>}
				{error && <p>Error!</p>} */}
				{

				}
				{course && user && (
					<ul className="Modules__ul">
						{console.log(user)}
						{console.log(trainedModules)}
						{trainedModules &&
							course.modules.filter((module) =>{
								return trainedModules.indexOf(module.title) !== -1;
							})
							.map((module, idx) => {
								const moduleIndex = course.modules.findIndex(checkModule => checkModule.title === module.title);
								const userLessons = user.roles.indexOf('participant') > -1 ? user.assignedLessons.filter((l) => l.active || l.complete) : false;
								const lessonsInProgress = user.roles.indexOf('participant') > -1 ? user.progress.lessons : false;
								const { col2Content, col3Content } = getModulePreviewCardContent(module, moduleIndex, userLessons, lessonsInProgress);

								return (
									<li key={idx}>
										<ColumnCard
											image={module.metadata.featured_image}
											col2Content={col2Content}
											col3Content={col3Content}
											icon="book"
											withPadding
										/>
									</li>
								);
							})
						}
						{!trainedModules &&
							
							<li>
								Modules could not be loaded.
							</li>
						
						}
					</ul>
				)}
			</div>
		</div>
	);
};

export default Modules;
