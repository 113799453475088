import { useEffect, useState } from 'react';
import localforage from 'localforage';
import { MiniProgressCard, ProfileCard } from '../../components';
// import { useClients } from '../../hooks/useClients';

export const HealthEducatorSection = ({ user }) => {
	const [clients, setClients] = useState(null);
	const [clientProgress, setClientProgress] = useState([]);
	const [ownClients, setOwnClients] = useState([]);

	useEffect(() => {
		(async () => {
			const localClients = await localforage.getItem('clients');

			if (localClients) setClients(localClients);
		})();
	}, []);

	useEffect(() => {
		if (!clients) return;

		// console.log(clients);

		const clientsFiltered = clients.filter((c) => user.clients.find((uc) => uc.id === c.id));

		clientsFiltered.sort((a, b) => {
			if (a.lastUpdated === b.lastUpdated) return 0;

			return a.lastUpdated > b.lastUpdated ? 1 : -1;
		});

		const progress = clientsFiltered.reduce((acc, curr) => {
			if (!curr.progress) return acc;

			const completedLessons = curr.progress.lessons
				.filter((l) => l.complete)
				.sort((a, b) => (a.complete < b.complete ? 1 : -1))
				.map((completedLesson) => {
					completedLesson.clientData = {
						by: curr.profile.fullname,
						portrait: curr.profile.portrait ? curr.profile.portrait : null,
					};

					return completedLesson;
				});

			return completedLessons.length > 0 ? [...acc, ...completedLessons] : acc;
		}, []);

		setClientProgress(progress);
		setOwnClients(clientsFiltered);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clients]);

	return (
		<div className="wrapper he">
			<div className="flexgrid">
				<div className="flexgrid__main">
					<h5 className="subhead">My Clients</h5>
					<ul className="card-grid">
						{ownClients &&
							ownClients.map((clientData, idx) =>
								clientData.profile ? (
									<ProfileCard
										as="li"
										key={`client-profile-${idx}`}
										userProfile={clientData.profile}
										link={`/my-clients/${clientData.id}`}
										healthEducator={user}
									/>
								) : null
							)}
					</ul>
				</div>
				<div>
					<h4 className="group-title h5">My Clients' Progress</h4>
					<ul className="Dashboard__my-progress-list">
						{clientProgress &&
							clientProgress
								.sort((a, b) => (a.complete < b.complete ? 1 : -1))
								.map((lesson, lessinIdx) => {
									return (
										<li key={`lesson-${lesson.slug}-progress-card-${lessinIdx}`}>
											<MiniProgressCard lessonInProgress={lesson} />
										</li>
									);
								})}
					</ul>
				</div>
			</div>
		</div>
	);
};
