import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import localforage from 'localforage';
import { useAuth } from '../../hooks/useAuth';
import { useToggle } from '../../hooks/useToggle';
import { useViewAs } from '../../hooks/useViewAs';
import Dropdown from '../Dropdown/Dropdown';
import { ReactComponent as Logo } from '../../assets/logo.svg';

import './Header.scss';

const Header = (props) => {
	const [user, setLocalUser] = useState(false);
	const { switchUser, viewAsUser } = useViewAs();
	const [notificationsOpen, toggleNotifications] = useToggle(false);
	const [profileOpen, toggleProfile] = useToggle(false);
	const [viewAsOpen, toggleViewAs] = useToggle(false);
	const { signout } = useAuth();
	const location = useLocation();

	useEffect(() => {
		localforage.getItem('user').then((u) => {
			setLocalUser(u);
		});
	}, [viewAsUser]);

	useEffect(() => {
		notificationsOpen && toggleNotifications();
		profileOpen && toggleProfile();
		viewAsOpen && toggleViewAs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const viewAsHandler = (clientId) => {
		console.log('View as button clicked');
		toggleViewAs();

		switchUser(clientId);
	};

	return (
		<div className="Header">
			<div className="wrapper">
				<Link to="/dashboard" className="logo">
					<Logo />
					<span className="tagline">Johns Hopkins Center for American Indian Health</span>
				</Link>

				<nav className="Header__nav">
					<ul>
						{user.clients && !viewAsUser && (
							<li className="viewas-clients">
								<Dropdown icon="view">
									<p>View the app as if you are logged in as one of your clients</p>
									<ul>
										{user.clients.map((client) => {
											return (
												<li key={`client-${client.id}`}>
													<button
														onClick={() => {
															viewAsHandler(client.id);
														}}>
														{client.profile.fullname.trim() !== ''
															? client.profile.fullname
															: client.profile.username}
													</button>
												</li>
											);
										})}
									</ul>
								</Dropdown>
							</li>
						)}
						{/* <li>
							<Button onClick={toggleNotifications} btnStyle="icon" className={notificationsOpen ? 'active' : undefined}>
								<Icon name="notification" />
							</Button>
							{notificationsOpen && <Notifications />}
						</li> */}
						<li>
							<Dropdown icon="user">
								<Link to="/my-profile">My Profile</Link>
								<Link to="/my-profile/settings">Settings</Link>
								<Link to="#" onClick={signout}>
									Log out
								</Link>
							</Dropdown>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
};

export default Header;
