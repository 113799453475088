import { forwardRef } from 'react';
import { Icon } from '..';
import './FormToggle.scss';

const FormToggle = forwardRef(({ id, onChange, defaultChecked }, ref) => {
	return (
		<label className="FormToggle" htmlFor={id}>
			<input type="checkbox" id={id} onChange={onChange} ref={ref} defaultChecked={defaultChecked} />
			<span className="slider">
				<Icon name="check" />
			</span>
		</label>
	);
});

export default FormToggle;
