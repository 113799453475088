import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import localforage from 'localforage';
import { useCourse } from '../../hooks/useCourse';
import { useUpdateProgress } from '../../hooks/useUpdateProgress';
import { Button, PageHeader, TableOfContents, LessonSection, Modal } from '../../components';

import './Lesson.scss';

const Lesson = (props) => {
	const history = useHistory();
	const { isLoading, error, data: course } = useCourse();
	const [user, setLocalUser] = useState(false);
	const [previousActivityData, setPreviousActivityData] = useState([]);
	const [currentActivityData, setCurrentActivityData] = useState([]);
	const [completeModalOpen, setCompleteModalOpen] = useState(false);
	const lesson =
		course &&
		course.modules
			.find((m) => m.slug === props.match.params.slug)
			.lessons.find((lesson) => lesson.slug === props.match.params.lessonSlug);
	const lessonStartedMutation = useUpdateProgress('LESSON_STARTED');
	const lessonCompleteMutation = useUpdateProgress('LESSON_COMPLETE');
	const sectionStartedMutation = useUpdateProgress('SECTION_STARTED');
	const sectionCompleteMutation = useUpdateProgress('SECTION_COMPLETE');
	let mutationPending = false;

	// const getPreviousActivityData = (u) => {
	// 	const lessonInUserProgress = u.progress.lessons.find((l) => l.slug === props.match.params.lessonSlug);
	// 	const sectionInUserProgress = lessonInUserProgress
	// 		? lessonInUserProgress.sections.find((s) => s.slug === props.match.params.sectionSlug)
	// 		: false;

	// 	return sectionInUserProgress && sectionInUserProgress.activityData ? sectionInUserProgress.activityData : [];
	// };

	const updateActivityData = (newData) => {
		setCurrentActivityData((old) => {
			return { ...old, ...newData };
		});
	};

	useEffect(() => {
		localforage.getItem('user').then((u) => {
			setLocalUser(u);

			if (u.roles.indexOf('participant') < 0) return;

			const lessonInProgress = u.progress.lessons.find((l) => l.slug === props.match.params.lessonSlug);

			if (!lessonInProgress && lesson) {
				lessonStartedMutation.mutate({
					moduleSlug: lesson.moduleSlug,
					moduleTitle: lesson.moduleTitle,
					lessonSlug: lesson.slug,
					lessonTitle: lesson.title,
					firstSectionSlug: lesson.metadata.sections[0].slug,
				});
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scroll({
			top: 0,
			behavior: 'smooth',
		});

		console.log('useEffect called for sectionSlug match prop change.');

		if (!lesson || !user || user.roles.indexOf('participant') < 0) return;

		sectionStartedMutation.mutate({
			lessonSlug: props.match.params.lessonSlug,
			sectionSlug: props.match.params.sectionSlug,
		});

		// setPreviousActivityData(getPreviousActivityData(user));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.match.params.sectionSlug]);

	if (!user) return 'Loading...';

	if (isLoading) return <p>loading...</p>;

	if (error) return <p>{error.message}</p>;

	const currentSectionIndex = lesson.metadata.sections.findIndex((section) => section.slug === props.match.params.sectionSlug);
	const section = lesson.metadata.sections[currentSectionIndex];
	const nextSection =
		currentSectionIndex >= lesson.metadata.sections.length - 1 ? false : lesson.metadata.sections[currentSectionIndex + 1];
	const nextSectionLink = nextSection
		? `/modules/${props.match.params.slug}/${props.match.params.lessonSlug}/${nextSection.slug}`
		: false;

	const completeClickHandler = (ev) => {
		// console.log('last section in lesson, clicked on complete, marking lesson as complete ONLY FOR PARTICIPANTS.');

		if (user.roles.indexOf('participant') > -1) {
			lessonCompleteMutation.mutateAsync({ lessonSlug: props.match.params.lessonSlug }).then((response) => {
				setCompleteModalOpen(true);
			});
		} else {
			setCompleteModalOpen(true);
		}
	};

	const continueClickHandler = (ev) => {
		if (user.roles.indexOf('participant') > -1) {
			// console.log(currentActivityData);

			mutationPending = true;

			sectionCompleteMutation
				.mutateAsync({
					lessonSlug: props.match.params.lessonSlug,
					sectionSlug: props.match.params.sectionSlug,
					activityData: currentActivityData,
				})
				.then((response) => {
					mutationPending = false;
					// console.log('section complete mutation finished');
				});
		}

		history.push(nextSectionLink);
	};

	return (
		<div className="Lesson">
			<PageHeader title={lesson.title}></PageHeader>
			<div className="wrapper">
				<div className="Lesson__section">
					{/* <p>Current activity data: {JSON.stringify(currentActivityData)}</p> */}
					<LessonSection
						lessonSlug={lesson.slug}
						section={section}
						user={user}
						previousActivityData={previousActivityData}
						activityDataChangeHandler={updateActivityData}
					/>
					<Button onClick={nextSectionLink ? continueClickHandler : completeClickHandler}>
						{nextSectionLink ? 'Continue' : 'Complete'}
					</Button>
				</div>
				<aside className="Lesson__aside">
					<TableOfContents sections={lesson.metadata.sections} currentSectionIndex={currentSectionIndex} />
				</aside>
			</div>
			<Modal
				isOpen={completeModalOpen}
				closeHandler={() => {
					setCompleteModalOpen(false);
				}}>
				<h3>You have successfully completed this lesson.</h3>
				<Button
					onClick={() => {
						history.push(`/modules/${props.match.params.slug}`);
					}}
					disabled={mutationPending}>
					{mutationPending ? 'Waiting for sync to finish...' : 'Return to module'}
				</Button>
			</Modal>
		</div>
	);
};

export default Lesson;
