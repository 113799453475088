import { useState } from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import LoginBg from '../../assets/default-login-bg.jpg';
import { Button, FormInput } from '../../components';
import { useAuth } from '../../hooks/useAuth';
// import axios from '../../utils/api';

import './Login.scss';

const Login = (props) => {
	const auth = useAuth();
	const [errorMessage, setErrorMessage] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const FS_LOGIN_DATA = window.FS_LOGIN_DATA;

	// useEffect(() => {
	// 	axios.get('/wp-json/wp/v2/login-fields').then((r) => {
	// 		console.log(r.data);
	// 	});
	// }, []);

	const handleSubmit = (ev) => {
		ev.preventDefault();

		if (errorMessage) setErrorMessage();

		setIsLoading(true);

		auth.signin(ev.target.elements['sign_in_email'].value, ev.target.elements['sign_in_password'].value).then((response) => {
			setIsLoading(false);

			if (response && response.status === 403) {
				setErrorMessage('Your credentials are invalid.');
				return;
			}

			if (response && response.status === 'wrongRoles') {
				setErrorMessage('It looks like you have been assigned a deprecated user role, contact your administrator.');
				return;
			}
		});
	};

	return (
		<div className="Login">
			<div className="Login__image" style={{ backgroundImage: `url(${FS_LOGIN_DATA ? FS_LOGIN_DATA.image.url : LoginBg})` }}></div>
			<div className="Login__content">
				<div className="logo">
					<Logo />
				</div>

				<h2 className="h2">Welcome</h2>

				{FS_LOGIN_DATA ? (
					<div className="intro" dangerouslySetInnerHTML={{ __html: FS_LOGIN_DATA.intro }}></div>
				) : (
					<p>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae saepe commodi illo in deleniti praesentium ullam
						repudiandae porro tempore adipisci doloremque itaque, dolores ipsam et aperiam nam reprehenderit incidunt!
						Praesentium.
					</p>
				)}

				<form method="POST" onSubmit={handleSubmit}>
					<FormInput
						label="Email"
						type="text"
						name="sign_in_email"
						id="signInEmail"
						placeholder="Email or Username"
						autoComplete="username"
						required
					/>
					<FormInput
						label="Password"
						type="password"
						name="sign_in_password"
						id="signInPassword"
						autoComplete="current-password"
						placeholder="Password"
						required
					/>
					<Button btnStyle="primary" type="submit" disabled={isLoading}>
						{isLoading ? 'Logging in...' : 'Log in'}
					</Button>
				</form>

				{errorMessage && <p className="error color-maroon" dangerouslySetInnerHTML={{ __html: errorMessage }}></p>}

				{/* <Button btnStyle="link">Reset password</Button> */}
			</div>
		</div>
	);
};

export default Login;
