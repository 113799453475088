import axios from '../utils/api';
import localforage from 'localforage';
import { useMutation, useQueryClient } from 'react-query';

const computeLessonProgress = (user, lessonSlug) => {
	const targetLesson = user.assignedLessons.find((l) => l.slug === lessonSlug);
	const lessonProgress = user.progress.lessons.find((l) => l.slug === lessonSlug);
	const totalSections = targetLesson.metadata.sections.length;
	const totalCompletedSections = lessonProgress.sections.filter((section) => section.complete).length;

	return Math.round((totalCompletedSections * 100) / totalSections);
};

const setLessonStarted = async ({ moduleSlug, moduleTitle, lessonSlug, lessonTitle, firstSectionSlug }) => {
	const user = await localforage.getItem('user');
	const existingLessonIndex = user.progress.lessons.findIndex((lesson) => lesson.slug === lessonSlug);

	if (existingLessonIndex > -1) return user;

	user.progress.lessons.push({
		moduleSlug: moduleSlug,
		moduleTitle: moduleTitle,
		slug: lessonSlug,
		title: lessonTitle,
		progressPercentage: 0,
		sections: [{ slug: firstSectionSlug }],
	});

	user.progress.lastUpdated = Math.floor(Date.now() / 1000);
	user.lastUpdated = Math.floor(Date.now() / 1000);

	return localforage.setItem('user', user);

	// if (navigator.onLine) {
	// 	return axios
	// 		.post(`/wp-json/wp/v2/user/${user.id}/update`, { lastUpdated: user.lastUpdated, fields: { progress: user.progress } })
	// 		.then((response) => {
	// 			return localforage.setItem('user', user);
	// 		});
	// } else {
	// 	return localforage.setItem('user', user);
	// }
};

const setSectionStarted = async ({ lessonSlug, sectionSlug }) => {
	const user = await localforage.getItem('user');
	const existingLessonIndex = user.progress.lessons.findIndex((lesson) => lesson.slug === lessonSlug);

	if (existingLessonIndex < 0) return user;

	const existingSectionIndex = user.progress.lessons[existingLessonIndex].sections.findIndex((section) => section.slug === sectionSlug);

	if (existingSectionIndex > -1) return user;

	user.progress.lessons[existingLessonIndex].sections.push({ slug: sectionSlug });
	user.progress.lastUpdated = Math.floor(Date.now() / 1000);
	user.lastUpdated = Math.floor(Date.now() / 1000);

	return localforage.setItem('user', user);

	// if (navigator.onLine) {
	// 	return axios
	// 		.post(`/wp-json/wp/v2/user/${user.id}/update`, { lastUpdated: user.lastUpdated, fields: { progress: user.progress } })
	// 		.then((response) => localforage.setItem('user', user));
	// } else {
	// 	return localforage.setItem('user', user);
	// }
};

const setSectionComplete = async ({ lessonSlug, sectionSlug, activityData }) => {
	const user = await localforage.getItem('user');
	const existingLessonIndex = user.progress.lessons.findIndex((lesson) => lesson.slug === lessonSlug);

	if (existingLessonIndex < 0) return user;

	let existingSectionIndex = user.progress.lessons[existingLessonIndex].sections.findIndex((section) => section.slug === sectionSlug);
	const existingSectionComplete =
		user.progress.lessons[existingLessonIndex].sections[existingSectionIndex] &&
		user.progress.lessons[existingLessonIndex].sections[existingSectionIndex].complete;

	if (existingSectionIndex < 0) {
		user.progress.lessons[existingLessonIndex].sections.push({ slug: sectionSlug });
		existingSectionIndex = user.progress.lessons[existingLessonIndex].sections.findIndex((section) => section.slug === sectionSlug);
	}

	if (Object.keys(activityData).length > 0) {
		user.progress.lessons[existingLessonIndex].sections[existingSectionIndex].activityData = activityData;
	}

	if (existingSectionComplete) return localforage.setItem('user', user);

	user.progress.lessons[existingLessonIndex].sections[existingSectionIndex].complete = Math.floor(Date.now() / 1000);
	user.progress.lastUpdated = Math.floor(Date.now() / 1000);
	user.lastUpdated = Math.floor(Date.now() / 1000);
	user.progress.lessons[existingLessonIndex].progressPercentage = computeLessonProgress(user, lessonSlug);

	return localforage.setItem('user', user);

	// if (navigator.onLine) {
	// 	return axios
	// 		.post(`/wp-json/wp/v2/user/${user.id}/update`, { lastUpdated: user.lastUpdated, fields: { progress: user.progress } })
	// 		.then((response) => localforage.setItem('user', user));
	// } else {
	// 	return localforage.setItem('user', user);
	// }
};

const setLessonComplete = async ({ lessonSlug }) => {
	const user = await localforage.getItem('user');
	const lessonIndex = user.progress.lessons.findIndex((lesson) => lesson.slug === lessonSlug);
	const assignedLessonIndex = user.assignedLessons.findIndex((assignedLesson) => assignedLesson.slug === lessonSlug);

	if (lessonIndex < 0 || user.progress.lessons[lessonIndex].hasOwnProperty('complete')) return user;

	const completeTime = Math.floor(Date.now() / 1000);

	user.progress.lessons[lessonIndex].complete = completeTime;
	user.progress.lessons[lessonIndex].progressPercentage = 100;
	user.assignedLessons[assignedLessonIndex].complete = completeTime;

	user.progress.lessons[lessonIndex].sections.forEach((section) => {
		if (!section.complete) section.complete = completeTime;
	});

	user.progress.lastUpdated = completeTime;
	user.lastUpdated = completeTime;

	return localforage.setItem('user', user);

	// if (navigator.onLine) {
	// 	return axios
	// 		.post(`/wp-json/wp/v2/user/${user.id}/update`, {
	// 			lastUpdated: user.lastUpdated,
	// 			fields: { progress: user.progress, assignedLessons: user.assignedLessons },
	// 		})
	// 		.then((response) => localforage.setItem('user', user));
	// } else {
	// 	return localforage.setItem('user', user);
	// }
};

const resetProgress = (userId) => {
	const resetProgressObject = { lastUpdated: Math.floor(Date.now() / 1000), lessons: [] };

	return axios
		.post(`/wp-json/wp/v2/user/${userId}/update`, {
			lastUpdated: resetProgressObject.lastUpdated,
			fields: { progress: resetProgressObject },
		})
		.then(({ data: user }) => user);
};

export const useUpdateProgress = (action) => {
	const queryClient = useQueryClient();

	let mutationFn;

	switch (action) {
		case 'LESSON_STARTED':
			mutationFn = setLessonStarted;
			break;

		case 'LESSON_COMPLETE':
			mutationFn = setLessonComplete;
			break;

		case 'SECTION_STARTED':
			mutationFn = setSectionStarted;
			break;

		case 'SECTION_COMPLETE':
			mutationFn = setSectionComplete;
			break;

		case 'RESET':
			mutationFn = resetProgress;
			break;

		default:
			mutationFn = ({ user }) => user;
			break;
	}

	// console.log('Firing the following mutation: ', action);

	return useMutation(mutationFn, {
		onSuccess: async (data) => {
			const updatedUser = await localforage.getItem('user');

			queryClient.invalidateQueries('localUser');

			if (navigator.onLine) {
				axios
					.post(`/wp-json/wp/v2/user/${updatedUser.id}/update`, {
						lastUpdated: updatedUser.lastUpdated,
						fields: { progress: updatedUser.progress, assignedLessons: updatedUser.assignedLessons },
					})
					.then((response) => {
						console.log('User updated in database: ', response);
					});
			}
		},
	});
};
