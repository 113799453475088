import axios from './api';
import { Link } from 'react-router-dom';
import { Cta, DefaultPortrait, Icon } from '../components';
import { privateRoutes, elevatedRoutes, adminRoutes } from '../routes';

export const stateSelectOptions = [
	{
		label: 'Alabama',
		value: 'AL',
	},
	{
		label: 'Alaska',
		value: 'AK',
	},
	{
		label: 'American Samoa',
		value: 'AS',
	},
	{
		label: 'Arizona',
		value: 'AZ',
	},
	{
		label: 'Arkansas',
		value: 'AR',
	},
	{
		label: 'California',
		value: 'CA',
	},
	{
		label: 'Colorado',
		value: 'CO',
	},
	{
		label: 'Connecticut',
		value: 'CT',
	},
	{
		label: 'Delaware',
		value: 'DE',
	},
	{
		label: 'District Of Columbia',
		value: 'DC',
	},
	{
		label: 'Federated States Of Micronesia',
		value: 'FM',
	},
	{
		label: 'Florida',
		value: 'FL',
	},
	{
		label: 'Georgia',
		value: 'GA',
	},
	{
		label: 'Guam',
		value: 'GU',
	},
	{
		label: 'Hawaii',
		value: 'HI',
	},
	{
		label: 'Idaho',
		value: 'ID',
	},
	{
		label: 'Illinois',
		value: 'IL',
	},
	{
		label: 'Indiana',
		value: 'IN',
	},
	{
		label: 'Iowa',
		value: 'IA',
	},
	{
		label: 'Kansas',
		value: 'KS',
	},
	{
		label: 'Kentucky',
		value: 'KY',
	},
	{
		label: 'Louisiana',
		value: 'LA',
	},
	{
		label: 'Maine',
		value: 'ME',
	},
	{
		label: 'Marshall Islands',
		value: 'MH',
	},
	{
		label: 'Maryland',
		value: 'MD',
	},
	{
		label: 'Massachusetts',
		value: 'MA',
	},
	{
		label: 'Michigan',
		value: 'MI',
	},
	{
		label: 'Minnesota',
		value: 'MN',
	},
	{
		label: 'Mississippi',
		value: 'MS',
	},
	{
		label: 'Missouri',
		value: 'MO',
	},
	{
		label: 'Montana',
		value: 'MT',
	},
	{
		label: 'Nebraska',
		value: 'NE',
	},
	{
		label: 'Nevada',
		value: 'NV',
	},
	{
		label: 'New Hampshire',
		value: 'NH',
	},
	{
		label: 'New Jersey',
		value: 'NJ',
	},
	{
		label: 'New Mexico',
		value: 'NM',
	},
	{
		label: 'New York',
		value: 'NY',
	},
	{
		label: 'North Carolina',
		value: 'NC',
	},
	{
		label: 'North Dakota',
		value: 'ND',
	},
	{
		label: 'Northern Mariana Islands',
		value: 'MP',
	},
	{
		label: 'Ohio',
		value: 'OH',
	},
	{
		label: 'Oklahoma',
		value: 'OK',
	},
	{
		label: 'Oregon',
		value: 'OR',
	},
	{
		label: 'Palau',
		value: 'PW',
	},
	{
		label: 'Pennsylvania',
		value: 'PA',
	},
	{
		label: 'Puerto Rico',
		value: 'PR',
	},
	{
		label: 'Rhode Island',
		value: 'RI',
	},
	{
		label: 'South Carolina',
		value: 'SC',
	},
	{
		label: 'South Dakota',
		value: 'SD',
	},
	{
		label: 'Tennessee',
		value: 'TN',
	},
	{
		label: 'Texas',
		value: 'TX',
	},
	{
		label: 'Utah',
		value: 'UT',
	},
	{
		label: 'Vermont',
		value: 'VT',
	},
	{
		label: 'Virgin Islands',
		value: 'VI',
	},
	{
		label: 'Virginia',
		value: 'VA',
	},
	{
		label: 'Washington',
		value: 'WA',
	},
	{
		label: 'West Virginia',
		value: 'WV',
	},
	{
		label: 'Wisconsin',
		value: 'WI',
	},
	{
		label: 'Wyoming',
		value: 'WY',
	},
];

export const userIsAdmin = (userRoles) => userRoles.indexOf('administrator') > -1 || userRoles.indexOf('affiliate') > -1;

export const asyncUploadImage = async (inputFile) => {
	const formData = new FormData();

	formData.append('file', inputFile);
	formData.append('title', inputFile.name);

	let headers = {};
	headers['Content-Disposition'] = "'" + inputFile.name + "'";

	const { data: attachment } = await axios.post('/wp-json/wp/v2/media', formData, {
		'Content-Disposition': `form-data; filename="${inputFile.name}"`,
	});

	return attachment.id;
};

export const formatPhoneNumber = (phoneNumberString) => {
	const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		const intlCode = match[1] ? '+1 ' : '';
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}

	return phoneNumberString;
};

export const getAddress = (userProfile) => {
	let address = '';

	if (userProfile.street_address) address += userProfile.street_address;
	if (userProfile.aptunit) address += ' ' + userProfile.aptunit;
	if (userProfile.city) address += ' ' + userProfile.city + ', ';
	if (userProfile.state) address += ' ' + userProfile.state;
	if (userProfile.zip) address += ' ' + userProfile.zip;

	return address;
};

export const getIconNameByPath = (path) => {
	let name;

	switch (path) {
		case '/dashboard':
			name = 'home';
			break;

		case '/modules':
			name = 'book';
			break;

		case '/my-clients':
			name = 'user-group';
			break;

		case '/educators':
			name = 'health-educators';
			break;

		case '/my-profile':
			name = 'user';
			break;

		default:
			name = 'escape';
			break;
	}

	return name;
};

export const getModulePreviewCardContent = (moduleData, idx, userLessons = false, userLessonsInProgress = false, moduleLessons) => {
	let targetLessons = userLessons ? userLessons : moduleData.lessons;

	if (moduleLessons) targetLessons = moduleLessons;

	// targetLessons = targetLessons.filter((lesson) => lesson.moduleSlug === moduleData.slug);

	const col2Content = (
		<>
			<h5>Module {idx + 1}</h5>
			<h3>{moduleData.title}</h3>
			<div className="wysiwyg" dangerouslySetInnerHTML={{ __html: moduleData.metadata.intro }}></div>
			<Link to={`/modules/${moduleData.slug}`} className="btn">
				View
			</Link>
		</>
	);

	const col3Content =
		targetLessons.length === 0 ? (
			<>
				<h4>Lessons</h4>
				<p className="fineprint">No active lessons. Please talk with your Health Educator if you have any questions.</p>
			</>
		) : (
			<>
				<h4>Lessons</h4>
				<ul className="TableOfContents__list">
					{targetLessons.slice(0, 4).map((lesson, idx) => {
						let iconClass = 'icon';

						const lessonInProgress = userLessonsInProgress ? userLessonsInProgress.find((l) => l.slug === lesson.slug) : false;

						if (lessonInProgress) iconClass += ' in-progress';
						if (lessonInProgress && lessonInProgress.complete) iconClass += ' complete';

						return (
							<li key={idx}>
								<Link to={`/modules/${moduleData.slug}/${lesson.slug}`} className="link unviewed">
									<span className={`${iconClass} link__icon`}>
										<Icon name="check" />
									</span>
									<span className="title link__title">{lesson.title}</span>
								</Link>
							</li>
						);
					})}
				</ul>
				<Cta route={`/modules/${moduleData.slug}`}>View all lessons</Cta>
			</>
		);

	return {
		col2Content,
		col3Content,
	};
};

export const getModuleIntroCardContent = (module) => {
	const col2Content = (
		<>
			<h5>Module</h5>
			<h3>{module.title}</h3>
			<div className="wysiwyg" dangerouslySetInnerHTML={{ __html: module.metadata.intro }}></div>
		</>
	);

	const col3Content = (
		<>
			<h4>What You Will Learn</h4>
			{module.metadata.learning_points.length > 0 && (
				<ul>
					{module.metadata.learning_points.map((point, idx) => (
						<li key={idx}>
							<p>{point.description}</p>
						</li>
					))}
				</ul>
			)}
		</>
	);

	return {
		col2Content,
		col3Content,
	};
};

export const myProfileMe = (userProfile) => (
	<>
		<h3>{userProfile.fullname}</h3>
		<div className="profile">
			<div className="profile__item">
				<Icon name="phone" className="primary" /> {formatPhoneNumber(userProfile.phone_number)}
			</div>
			<div className="profile__item">
				<Icon name="mail" className="primary" />
				<a target="_blank" rel="noreferrer" href={`mailto:${userProfile.email}`}>
					{userProfile.email}
				</a>
			</div>
			<div className="profile__item">
				<Icon name="calendar" className="primary" /> {getAge(userProfile.date_of_birth)} years old
			</div>
			<div className="profile__item">
				<Icon name="location" className="primary" />
				{getAddress(userProfile)}
			</div>
		</div>
	</>
);

export const myProfileMyHealthEducator = (healthEducatorProfile) => (
	<div className="profile profile--he">
		<h5 className="profile__title">My Health Educator</h5>
		<div className="profile__item">
			{healthEducatorProfile.portrait ? (
				<img src={healthEducatorProfile.portrait.sizes.thumbnail} className="profile__pic" alt={healthEducatorProfile.fullname} />
			) : (
				<DefaultPortrait type="round" />
			)}

			<span className="profile__name">{healthEducatorProfile.fullname}</span>
		</div>
		<div className="profile__item">
			<Icon name="phone-small" className="primary" /> {formatPhoneNumber(healthEducatorProfile.phone_number.substring(0, 10))}
		</div>
		<div className="profile__item">
			<Icon name="mail-small" className="primary" /> {healthEducatorProfile.email}
		</div>
	</div>
);

export const getProfileCardContent = (userProfile) => {
	const col2Content = (
		<>
			<h3>{userProfile.fullname}</h3>
			<div className="profile">
				<div className="profile__item">
					<Icon name="phone" className="primary" /> {userProfile.phone_number}
				</div>
				<div className="profile__item">
					<Icon name="mail" className="primary" />
					<a target="_blank" rel="noreferrer" href={`mailto:${userProfile.email}`}>
						{userProfile.email}
					</a>
				</div>
				<div className="profile__item">
					<Icon name="calendar" className="primary" /> {getAge(userProfile.date_of_birth)} years old
				</div>
				<div className="profile__item">
					<Icon name="location" className="primary" />
					{getAddress(userProfile)}
				</div>
			</div>
		</>
	);

	const col3Content =
		userProfile.roles.indexOf('participant') > -1 ? (
			<div className="profile profile--he">
				<h5 className="profile__title">My Health Educator</h5>
				<div className="profile__item">
					<img
						src={userProfile.myHealthEducator.portrait ? userProfile.myHealthEducator.portrait.sizes.thumbnail : null}
						className="profile__pic"
						alt="Gray Anderson"
					/>
					<span className="profile__name">{userProfile.myHealthEducator.user_nicename}</span>
				</div>
				<div className="profile__item">
					<Icon name="phone-small" className="primary" /> {formatPhoneNumber(userProfile.myHealthEducator.phone_number)}
				</div>
				<div className="profile__item">
					<Icon name="mail-small" className="primary" /> {userProfile.myHealthEducator.user_email}
				</div>
			</div>
		) : null;

	return {
		col2Content,
		col3Content,
	};
};

export const getEducatorCardContent = (educator) => {
	const profileContent = (
		<>
			<h3>{educator.profile.fullname}</h3>
			<div className="profile">
				<div className="profile__item">
					<Icon name="phone" className="primary" /> {formatPhoneNumber(educator.profile.phone_number)}
				</div>
				<div className="profile__item">
					<Icon name="mail" className="primary" />{' '}
					<a target="_blank" rel="noreferrer" href={`mailto:${educator.profile.email}`}>
						{educator.profile.email}
					</a>
				</div>
				{educator.profile.date_of_birth && (
					<div className="profile__item">
						<Icon name="calendar" className="primary" /> {getAge(educator.profile.date_of_birth)} years old
					</div>
				)}
				<div className="profile__item">
					<Icon name="location" className="primary" />
					{getAddress(educator.profile)}
				</div>
			</div>
		</>
	);

	return { profileContent };
};

export const getClientCardContent = (client) => {

	const col2Content = client ? (
		<>
			<h3>{client.profile.fullname}</h3>
			<div className="profile">
				<div className="profile__item">
					<Icon name="phone" className="primary" /> {client.profile.phone_number}
				</div>
				<div className="profile__item">
					<Icon name="mail" className="primary" />{' '}
					<a target="_blank" rel="noreferrer" href={`mailto:${client.profile.email}`}>
						{client.profile.email}
					</a>
				</div>
				{client.profile.date_of_birth && (
					<div className="profile__item">
						<Icon name="calendar" className="primary" /> {getAge(client.profile.date_of_birth)} years old
					</div>
				)}
				<div className="profile__item">
					<Icon name="location" className="primary" />
					{getAddress(client.profile)}
				</div>
				<div className="profile__item">
					<Icon name="user" className="primary" />
					{client.healthEducators[0] && (
						<Cta route={`/educators/${client.healthEducators[0].id}`}>
							Assigned to {client.healthEducators[0].profile.fullname}
						</Cta>
					)}
				</div>
			</div>
		</>
	) : (
		'No client data.'
	);

	const col3Content = client ? (
		<>
			<Link className="btn btn--secondary" to={`/my-clients/${client.id}/lesson-order`}>
				Change Lesson Order <Icon name="reorder" />
			</Link>
			{/* <ButtonWithIcon
				label="View Client's Case"
				className="btn btn--secondary"
				icon="new-window"
				clickHandler={() => {
					console.log('VIEW CASE');
				}}
				disabled></ButtonWithIcon>
			<ButtonWithIcon
				label="Download Client's Data"
				className="btn btn--secondary"
				icon="download"
				clickHandler={() => {
					console.log('DOWNLOAD');
				}}
				disabled></ButtonWithIcon> */}
		</>
	) : (
		'No client data.'
	);

	return {
		col2Content,
		col3Content,
	};
};

export const getAge = (dateString) => {
	const today = new Date();
	const birthDate = new Date(dateString);
	// console.log(birthDate=="Invalid Date");
	// if(birthDate=="Invalid Date"){
		// return "";
	// }
	// else{
		let age = today.getFullYear() - birthDate.getFullYear();
		const m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	// }
};

export const unixTimestamp2TimeAgo = (timestamp) => {
	if (!timestamp) return false;

	const d = new Date();
	const nowTs = Math.floor(d.getTime() / 1000);
	const seconds = nowTs - timestamp;

	if (seconds > 6 * 30 * 24 * 3600) return 'over a 6 months ago';

	if (seconds > 30 * 24 * 3600) return 'over a month ago';

	if (seconds > 2 * 24 * 3600) return 'a few days ago';

	if (seconds > 24 * 3600) return 'yesterday';

	if (seconds > 3600) return 'a few hours ago';

	if (seconds > 1800) return 'Half an hour ago';

	if (seconds > 60) {
		const minutes = Math.floor(seconds / 60);
		return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
	}

	return 'Less than a minute ago';
};

export const getNavRoutesByRole = (userRoles) => {
	const commonRoutes = [
		{
			title: 'Dashboard',
			path: '/dashboard',
		},
		{
			title: 'Modules',
			path: '/modules',
		},
	];

	if (userRoles.indexOf('participant') > -1)
		return [
			...commonRoutes,
			{
				title: 'My Profile',
				path: '/my-profile',
			},
		];

	if (userRoles.indexOf('administrator') > -1 || userRoles.indexOf('affiliate') > -1)
		return [
			...commonRoutes,
			{
				title: 'Clients',
				path: '/my-clients',
			},
			{
				title: 'Health Educators',
				path: '/educators',
			},
			{
				title: 'My Profile',
				path: '/my-profile',
			},
		];

	return [
		...commonRoutes,
		{
			title: 'Clients',
			path: '/my-clients',
		},
		{
			title: 'My Profile',
			path: '/my-profile',
		},
	];
};

export const switchTheme = (roles) => {
	if (roles.indexOf('administrator') > -1 || roles.indexOf('affiliate') > -1 || roles.indexOf('health-educator') > -1) {
		document.body.classList.remove('theme--earth');
		document.body.classList.add('theme--sky');
	} else {
		document.body.classList.remove('theme--sky');
		document.body.classList.add('theme--earth');
	}
};

export const getRoutesByRoles = (roles) => {
	console.log(roles);
	let r = roles.indexOf('participant') < 0 ? [...privateRoutes, ...elevatedRoutes] : privateRoutes;
	if (roles.indexOf('administrator') > -1 || roles.indexOf('affiliate') > -1) r = r.concat(adminRoutes);

	return r;
};
