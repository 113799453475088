import { useState } from 'react';
// import ReactImageMagnify from 'react-image-magnify';
import { Icon } from '..';
import { useToggle } from '../../hooks/useToggle';

import './ImageCard.scss';

const ImageCard = ({ imageObject, caption }) => {
	const [lightboxVisible, setLightboxVisible] = useToggle(false);
	const [zoomLevel, setZoomLevel] = useState(1);
	const zoomChangeHandler = (ev) => setZoomLevel(ev.target.valueAsNumber);

	return (
		<div className="ImageCard">
			<div className="card card--col1 card--image">
				<img alt={imageObject.description} src={imageObject.url} />
				<button className="btn btn--zoom" onClick={setLightboxVisible}>
					<Icon name="zoom-more" />
				</button>
			</div>
			<div className={`modal modal--overlay ${lightboxVisible ? 'show' : 'hide'}`}>
				<div className="card card--image">
					<button className="btn btn--zoom" onClick={setLightboxVisible}>
						<Icon name="close" />
					</button>

					<div className="card--image__container">
						{/* <ReactImageMagnify
							enlargedImagePosition="over"
							smallImage={{
								alt: imageObject.description,
								isFluidWidth: true,
								src: imageObject.sizes.medium,
							}}
							largeImage={{
								src: imageObject.url,
								width: imageObject.width,
								height: imageObject.height,
							}}
						/> */}
						<img alt={caption} src={imageObject.url} style={{ transform: `scale(${zoomLevel})` }} draggable="true" />
					</div>

					<div className="caption">{caption}</div>

					<div className="image-zoom">
						<div className="zoom-range">
							<div
								className="icon-more"
								onClick={() => {
									setZoomLevel((prev) => (prev < 10 ? prev++ : prev));
								}}>
								<Icon name="zoom-more" />
							</div>
							<div
								className="icon-less"
								onClick={() => {
									setZoomLevel((prev) => (prev > 1 ? prev-- : prev));
								}}>
								<Icon name="zoom-less" />
							</div>
							<input className="customrange" type="range" min="1" max="10" value={zoomLevel} onChange={zoomChangeHandler} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ImageCard;
