import localforage from 'localforage';
import { useQuery } from 'react-query';
import axios from '../utils/api';

export const useClients = () => {
	const blogId = localStorage.getItem('blogId');
	const clientsEndpoint = blogId ? `/wp-json/wp/v2/${+blogId}/clients` : '/wp-json/wp/v2/clients';
	return useQuery(
		'clients',
		() => localforage.getItem('clients'),
		// () =>
		// 	navigator.onLine
		// 		? axios.get(clientsEndpoint).then((response) => localforage.setItem('clients', response.data))
		// 		: localforage.getItem('clients'),
		// { retry: false, refetchOnMount: false }
	);
};
