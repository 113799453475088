import { useMe } from '../../hooks/useMe';
import { PageHeader, ProfileCard } from '../../components';

import './Educators.scss';

const Educators = ({ pageTitle }) => {
	const user = useMe();

	return (
		<div className="Educators">
			<PageHeader
				title={pageTitle}
				icon="user-plus"
				subhead={`${user.educators.length} educator${user.educators.length === 1 ? '' : 's'}`}></PageHeader>
			<div className="wrapper">
				<div className="Educators__grid">
					{user.educators.map((educator, idx) => (
						<ProfileCard key={`educator-profile-${idx}`} userProfile={educator.profile} link={`/educators/${educator.id}`} />
					))}
				</div>
			</div>
		</div>
	);
};

export default Educators;
