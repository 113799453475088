import { useEffect } from 'react';
import { useMe } from '../../hooks/useMe';
import { useCourse } from '../../hooks/useCourse';
import { Link } from 'react-router-dom';
import { ProgressBar, ColumnCard, PageHeader } from '../../components';
import { getModuleIntroCardContent, unixTimestamp2TimeAgo } from '../../utils/misc';

import './Module.scss';

const Module = (props) => {
	const user = useMe();
	const { isLoading, error, data: course } = useCourse();

	useEffect(() => {
		window.scroll({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	if (isLoading)
		return (
			<div className="Module">
				<PageHeader title="Loading..." icon="book"></PageHeader>
			</div>
		);

	if (error)
		return (
			<div className="Module">
				<PageHeader title="Error!" icon="book"></PageHeader>
			</div>
		);

	const module = course.modules.find((m) => m.slug === props.match.params.slug);
	const moduleContent = getModuleIntroCardContent(module);
	const targetLessons =
		user.roles.indexOf('participant') > -1
			? user.assignedLessons.filter((l) => l.moduleSlug === module.slug && l.active)
			: module.lessons;

	const lessonAlsoInProgress = (lessonId) => {
		if (user.roles.indexOf('participant') < 0) return false;

		return user.progress.lessons.find((lesson) => lesson.id === lessonId);
	};

	return (
		<div className="Module">
			<PageHeader title={module.title} icon="book"></PageHeader>

			<div className="wrapper">
				<ColumnCard
					image={module.metadata.featured_image}
					col2Content={moduleContent.col2Content}
					col3Content={moduleContent.col3Content}
					icon="book"
					withPadding
				/>

				{!navigator.onLine && (
					<div className="Module__offline-notification">Lessons not fully downloaded may have contents appear incorrectly</div>
				)}

				{targetLessons && (
					<div className="Module__lesson-grid">
						{targetLessons.map((lesson, idx) => {
							const lessonInProgress = lessonAlsoInProgress(lesson.id);

							return (
								<div className="LessonCard" key={idx}>
									{lessonInProgress && (
										<ProgressBar
											progressPercentage={lessonInProgress.progressPercentage}
											completeTime={lessonInProgress.complete && unixTimestamp2TimeAgo(lessonInProgress.complete)}
										/>
									)}

									<div
										className="LessonCard__image"
										style={{
											backgroundImage: `url(${
												lesson.metadata.featured_image
													? lesson.metadata.featured_image
													: module.metadata.featured_image
											})`,
										}}></div>
									<div className="LessonCard__content">
										<h5>{module.title}</h5>
										<h4>{lesson.title}</h4>
										<div className="excerpt" dangerouslySetInnerHTML={{ __html: lesson.metadata.excerpt }}></div>
										<Link className="Button" to={`/modules/${module.slug}/${lesson.slug}`}>
											View Lesson
										</Link>
									</div>
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default Module;
