import { ColumnCard, PageHeader, ProfileCard } from '../../components';
import { useMe } from '../../hooks/useMe';
import { getEducatorCardContent } from '../../utils/misc';

import './Educator.scss';

const Educator = (props) => {
	const user = useMe();
	const educator = user.educators.find((educator) => educator.id === +props.match.params.educatorId);
	const { profileContent } = getEducatorCardContent(educator);

	return (
		<div className="Educator">
			<PageHeader title="Educator Information" icon="health-educators">
				<div className="container--btn">
					{/* <Button>Send Message</Button> */}
					{/* <Link className="btn btn--secondary" to={`/my-clients/${profileData.slug}/edit`}>
							Edit Client
						</Link> */}
				</div>
			</PageHeader>
			<div className="wrapper">
				<ColumnCard
					image={educator.profile.portrait ? educator.profile.portrait.url : null}
					col2Content={profileContent}
					col3Content={null}
				/>

				{educator.clients.length > 0 && (
					<>
						<h5 className="h5">Assigned clients</h5>

						<div className="Educator__clients-grid">
							{educator.clients.map((client) => (
								<ProfileCard
									key={`educator-client-${client.id}`}
									userProfile={client.profile}
									link={`/my-clients/${client.id}`}
									healthEducator={educator}
								/>
							))}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Educator;
