import { useEffect, useRef, useState } from 'react';
import { Icon } from '..';

import './OfflineNotifications.scss';

const OfflineNotifications = ({ offlineSetter }) => {
	const lastSyncedDays = useRef(false);
	const lastSyncMessage = useRef('');
	const [isOffline, setIsOffline] = useState(!navigator.onLine);

	useEffect(() => {
		if (localStorage.getItem('lastSynced') !== null) {
			const lastSyncedTimestamp = +localStorage.getItem('lastSynced');
			const d = new Date();
			const nowTs = Math.floor(d.getTime() / 1000);
			const seconds = nowTs - lastSyncedTimestamp;

			lastSyncedDays.current = Math.floor(seconds / 86400);

			if (lastSyncedDays.current === 0) lastSyncMessage.current = 'System has not synced today.';
			if (lastSyncedDays.current === 1) lastSyncMessage.current = 'System has not synced since yesterday.';
			if (lastSyncedDays.current > 1) lastSyncMessage.current = `System has not synced in ${lastSyncedDays.current} days`;
		}

		const onlineListener = window.addEventListener('online', (ev) => {
			setIsOffline(false);
			offlineSetter(false);
		});

		const offlineListener = window.addEventListener('offline', (ev) => {
			setIsOffline(true);
			offlineSetter(true);
		});

		return () => {
			window.removeEventListener('online', onlineListener);
			window.removeEventListener('offline', offlineListener);
		};
	}, []);

	return (
		isOffline && (
			<li className="Layout__notification Layout__notification--offline">
				<span className="Layout__notification__text">
					<Icon name="warning" /> You are offline
				</span>
				{lastSyncedDays.current !== false && <span className="Layout__notification__sync-notice">{lastSyncMessage.current}</span>}
			</li>
		)
	);
};

export default OfflineNotifications;
