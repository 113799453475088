import { useRef } from 'react';
import { useMe } from '../../hooks/useMe';

import './SimpleForm.scss';

const SimpleForm = ({ sectionFormId, fields, updateCurrentDataHandler, noData }) => {
	const formRef = useRef();
	const currentUser = useMe();
	const localStorageActivityData = localStorage.getItem(`user-${currentUser.id}-activity-data`);
	const previousData = localStorageActivityData && !noData ? JSON.parse(localStorageActivityData)[sectionFormId] : [];

	const changeHandler = (ev) => {
		let userActivityPayload = {};
		let payload = {};
		let data = [];

		Array.prototype.forEach.call(formRef.current.elements, (el) => {
			const recordCondition =
				((el.type === 'text' || el.type === 'textarea') && el.value !== '') ||
				((el.type === 'radio' || el.type === 'checkbox') && el.checked);

			if (recordCondition) payload[el.name] = el.value;
		});

		data[sectionFormId] = payload;
		userActivityPayload[sectionFormId] = payload;
		userActivityPayload['lastUpdated'] = Math.floor(Date.now() / 1000);

		if (localStorageActivityData) {
			const userActivityData = JSON.parse(localStorageActivityData);

			userActivityData[sectionFormId] = payload;
			userActivityData['lastUpdated'] = Math.floor(Date.now() / 1000);

			localStorage.setItem(`user-${currentUser.id}-activity-data`, JSON.stringify(userActivityData));
		} else {
			localStorage.setItem(`user-${currentUser.id}-activity-data`, JSON.stringify(userActivityPayload));
		}

		updateCurrentDataHandler(data);
	};

	const getPreviousFieldData = (fieldId) => {
		if (!previousData) return false;

		return previousData[fieldId];
	};

	return (
		<form className="SimpleForm" ref={formRef}>
			{fields.map((field, idx) => {
				const fieldId = `form-${field.formId}-field-${field.id}`;
				const previousFieldValue = getPreviousFieldData(fieldId);

				switch (field.type) {
					case 'text':
						return (
							<div className={`SimpleForm__field SimpleForm__field--${field.layoutGridColumnSpan}`} key={fieldId}>
								<h4>{field.label}</h4>
								<input
									onChange={changeHandler}
									id={fieldId}
									name={fieldId}
									type="text"
									defaultValue={previousFieldValue ? previousFieldValue : field.defaultValue}
									placeholder={field.placeholder}
								/>
							</div>
						);

					case 'textarea':
						return (
							<div className={`SimpleForm__field SimpleForm__field--${field.layoutGridColumnSpan}`} key={fieldId}>
								<h4>{field.label}</h4>
								<textarea
									onChange={changeHandler}
									id={fieldId}
									name={fieldId}
									defaultValue={previousFieldValue ? previousFieldValue : field.defaultValue}
									rows="10"
									cols="50"></textarea>
							</div>
						);

					case 'checkbox':
						return (
							<div className={`SimpleForm__field SimpleForm__field--${field.layoutGridColumnSpan}`} key={fieldId}>
								<h4>{field.label}</h4>
								{field.choices.map((choice, choiceIdx) => {
									const choiceId = `${fieldId}-choice-${choiceIdx}`;
									const previousChoiceValue = getPreviousFieldData(choiceId);

									return (
										<div
											className={`SimpleForm__field__checkbox ${
												previousChoiceValue && previousChoiceValue === choice.value
													? 'SimpleForm__field__checkbox--checked'
													: ''
											}`}
											key={choiceId}>
											<input
												type="checkbox"
												onClick={changeHandler}
												name={choiceId}
												id={choiceId}
												value={choice.value}
												defaultChecked={previousChoiceValue && previousChoiceValue === choice.value}
											/>
											<label htmlFor={choiceId}>{choice.text}</label>
										</div>
									);
								})}
							</div>
						);

					case 'radio':
						return (
							<div className={`SimpleForm__field SimpleForm__field--${field.layoutGridColumnSpan}`} key={fieldId}>
								<h4>{field.label}</h4>
								{field.choices.map((choice, choiceIdx) => {
									const choiceId = `${fieldId}-choice-${choiceIdx}`;

									return (
										<div
											className={`SimpleForm__field__radio ${
												previousFieldValue && previousFieldValue === choice.value
													? 'SimpleForm__field__radio--checked'
													: ''
											}`}
											key={choiceId}>
											<input
												type="radio"
												onClick={changeHandler}
												name={fieldId}
												id={choiceId}
												value={choice.value}
												defaultChecked={previousFieldValue && previousFieldValue === choice.value}
											/>
											<label htmlFor={choiceId}>{choice.text}</label>
										</div>
									);
								})}
							</div>
						);

					default:
						return null;
				}
			})}
		</form>
	);
};

export default SimpleForm;
