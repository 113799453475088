import localforage from 'localforage';
import { useQuery } from 'react-query';
import axios from '../utils/api';

export const useCourse = () =>
	useQuery(
		'course',
		// () => {
		// 	console.log('fetching course');
		// 	return localforage.getItem('course');
		// },
		() =>
			navigator.onLine
				? axios.get(`/wp-json/wp/v2/course`).then((response) => response.data)
				: localforage.getItem('course').then((course) => course),
		{ retry: false, refetchOnMount: 'always' }
	);

// import { createContext, useContext } from 'react';
// const courseContext = createContext();

// export function ProvideCourse({ course, children }) {
// 	return <courseContext.Provider value={course}>{children}</courseContext.Provider>;
// }

// export const useCourse = () => {
// 	return useContext(courseContext);
// };

// // function useProvideCourse = ()
