import { useReducer } from 'react';
import { sortableReducer } from '../reducers/sortableReducer';

export const useSortable = (initialItems) => {
	const [sortableActiveState, sortableActiveDispatch] = useReducer(sortableReducer, {
		items: initialItems.filter((item) => item.active && !item.complete),
	});

	const [sortableInactiveState, sortableInactiveDispatch] = useReducer(sortableReducer, {
		items: initialItems.filter((item) => !item.active && !item.complete),
	});

	const [sortableCompletedState, sortableCompletedDispatch] = useReducer(sortableReducer, {
		items: initialItems.filter((item) => item.complete),
	});

	const incompleteToCompleteMove = (dragResult) => {
		const itemToMove =
			dragResult.source.droppableId === 'active-lessons'
				? sortableActiveState.items[dragResult.source.index]
				: sortableInactiveState.items[dragResult.source.index];
		const sourceDispatch = dragResult.source.droppableId === 'active-lessons' ? sortableActiveDispatch : sortableInactiveDispatch;

		itemToMove.complete = Math.floor(Date.now() / 1000);
		itemToMove.active = false;

		sourceDispatch({ type: 'REMOVE_ITEM', payload: { targetIndex: dragResult.source.index } });
		sortableCompletedDispatch({ type: 'ADD_ITEM', payload: { targetIndex: dragResult.destination.index, newItem: itemToMove } });
	};

	const completeToIncompleteMove = (dragResult) => {
		const itemToMove = sortableCompletedState.items[dragResult.source.index];
		const targetDispatch = dragResult.destination.droppableId === 'active-lessons' ? sortableActiveDispatch : sortableInactiveDispatch;

		itemToMove.complete = null;
		itemToMove.active = dragResult.destination.droppableId === 'active-lessons';

		sortableCompletedDispatch({ type: 'REMOVE_ITEM', payload: { targetIndex: dragResult.source.index } });
		targetDispatch({ type: 'ADD_ITEM', payload: { targetIndex: dragResult.destination.index, newItem: itemToMove } });
	};

	const inactiveToActiveMove = (dragResult) => {
		const itemToMove = sortableInactiveState.items[dragResult.source.index];
		itemToMove.active = true;
		sortableInactiveDispatch({ type: 'REMOVE_ITEM', payload: { targetIndex: dragResult.source.index } });
		sortableActiveDispatch({ type: 'ADD_ITEM', payload: { targetIndex: dragResult.destination.index, newItem: itemToMove } });
	};

	const activeToInactiveMove = (dragResult) => {
		const itemToMove = sortableActiveState.items[dragResult.source.index];
		itemToMove.active = false;
		sortableActiveDispatch({ type: 'REMOVE_ITEM', payload: { targetIndex: dragResult.source.index } });
		sortableInactiveDispatch({ type: 'ADD_ITEM', payload: { targetIndex: dragResult.destination.index, newItem: itemToMove } });
	};

	const reorder = (dragResult) => {
		// Item needs to be moved within active or inactive
		const targetDispatch = dragResult.source.droppableId === 'active-lessons' ? sortableActiveDispatch : sortableInactiveDispatch;

		targetDispatch({ type: 'MOVE_ITEM', payload: { fromIndex: dragResult.source.index, toIndex: dragResult.destination.index } });
	};

	function dragEndHandler(result) {
		if (!result.destination) return;

		console.log(result);

		// Item needs to be moved between lists
		if (result.source.droppableId !== result.destination.droppableId) {
			if (result.source.droppableId === 'active-lessons') {
				if (result.destination.droppableId === 'inactive-lessons') activeToInactiveMove(result);
				if (result.destination.droppableId === 'completed-lessons') incompleteToCompleteMove(result);
			}

			if (result.source.droppableId === 'inactive-lessons') {
				if (result.destination.droppableId === 'active-lessons') inactiveToActiveMove(result);
				if (result.destination.droppableId === 'completed-lessons') incompleteToCompleteMove(result);
			}

			if (result.source.droppableId === 'completed-lessons') completeToIncompleteMove(result);
		} else {
			reorder(result);
		}
	}

	const inactiveToActiveClick = (index) => {
		const itemToMove = sortableInactiveState.items[index];
		itemToMove.active = true;
		sortableInactiveDispatch({ type: 'REMOVE_ITEM', payload: { targetIndex: index } });
		sortableActiveDispatch({ type: 'ADD_ITEM', payload: { targetIndex: 0, newItem: itemToMove } });
	};

	const activeToCompletedClick = (index) => {
		const itemToMove = sortableActiveState.items[index];
		itemToMove.active = false;
		sortableActiveDispatch({ type: 'REMOVE_ITEM', payload: { targetIndex: index } });
		sortableCompletedDispatch({ type: 'ADD_ITEM', payload: { targetIndex: 0, newItem: itemToMove } });
	};

	const completedToInactiveClick = (index) => {
		const itemToMove = sortableCompletedState.items[index];
		itemToMove.active = false;
		sortableCompletedDispatch({ type: 'REMOVE_ITEM', payload: { targetIndex: index } });
		sortableInactiveDispatch({ type: 'ADD_ITEM', payload: { targetIndex: 0, newItem: itemToMove } });
	};

	const buttonClickHandler = (event) =>{
		event.preventDefault();
		const cardType = event.target.getAttribute('card-type');
		const index = event.target.parentNode.attributes['data-rbd-draggable-id'].value.split('_')[1];

		// console.log(cardType);

		if(cardType == 'inactive'){
			inactiveToActiveClick(index);
		}
		if(cardType == 'active'){
			activeToCompletedClick(index);
		}
		if(cardType == 'complete'){
			completedToInactiveClick(index);
		}
	}

	return [sortableActiveState, sortableInactiveState, sortableCompletedState, dragEndHandler, buttonClickHandler];
};
