import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useToggle } from '../../hooks/useToggle';
import { getIconNameByPath, getNavRoutesByRole } from '../../utils/misc';
import { Icon } from '../index';
import './Nav.scss';

const Nav = ({ currentRoutes, userRoles, className }) => {
	const history = useHistory();
	const { signout } = useAuth();
	const [navOpen, toggleNav] = useToggle(false);
	// const [messageModalVisible, setMessageModalVisible] = useToggle(false);
	const navRoutes = getNavRoutesByRole(userRoles);

	const navButtonClickHandler = (ev) => {
		ev.preventDefault();
		toggleNav();
	};

	useEffect(() => {
		const unlisten = history.listen((location, action) => {
			if (navOpen) toggleNav();
		});

		return unlisten;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, navOpen]);

	return (
		<nav className={`Nav ${navOpen ? 'Nav--open' : ''} ${className}`}>
			<button className="Nav__trigger" onClick={navButtonClickHandler}>
				<Icon name={navOpen ? 'close' : 'hamburger'} />
			</button>
			<ul className="Nav__links">
				{navRoutes.map((route) => {
					return (
						<li key={route.path}>
							<NavLink to={route.path}>
								<span className="icon">
									<Icon name={getIconNameByPath(route.path)} />
								</span>
								<span className="title">{route.title}</span>
							</NavLink>
						</li>
					);
				})}

				{userRoles.indexOf('participant') > -1 && (
					<>
						{/* <li className="extra">
							<button>
								<span className="icon">
									<Icon name="message" />
								</span>
								<span className="title">Ask a Question</span>
							</button>
						</li> */}
						<li className="extra">
							<button onClick={signout}>
								<span className="icon">
									<Icon name="escape" />
								</span>
								<span className="title">Quick Exit</span>
							</button>
						</li>
					</>
				)}
			</ul>
		</nav>
	);

	// return (
	// 	<>
	// 		<div className={`Nav ${!navOpen && 'collapsed'}`}>
	// 			<div className="nav-header" onClick={navButtonClickHandler}>
	// 				{!navOpen && <IconButton icon="hamburger" className="hamburger" />}
	// 				{navOpen && <IconButton icon="close" className="close" />}
	// 			</div>
	// 			<nav>
	// 				<ul className="links">
	// 					{navRoutes.map((route) => {
	// 						return (
	// 							<li key={route.path}>
	// 								<NavLink to={route.path}>
	// 									<Icon name={getIconNameByPath(route.path)} />
	// 									<span>{route.title}</span>
	// 								</NavLink>
	// 							</li>
	// 						);
	// 					})}
	// 				</ul>
	// 				<ul className="ctas">
	// 					{userRoles.indexOf('participant') > -1 && navigator.onLine && (
	// 						<li>
	// 							<NavLink className="cta" to="#" onClick={setMessageModalVisible}>
	// 								<IconButton icon="message" />
	// 								<span>Ask a Question</span>
	// 							</NavLink>
	// 						</li>
	// 					)}
	// 					{userRoles.indexOf('participant') > -1 && (
	// 						<li>
	// 							<NavLink className="cta" to="#" onClick={signout}>
	// 								<IconButton icon="escape" />
	// 								<span>Quick Exit</span>
	// 							</NavLink>
	// 						</li>
	// 					)}
	// 				</ul>
	// 			</nav>
	// 		</div>
	// 		<div className={`modal modal--overlay ${messageModalVisible ? 'show' : 'hide'}`}>
	// 			<div className="card">
	// 				<button className="btn btn--close btn--zoom" onClick={setMessageModalVisible}>
	// 					<Icon name="close" />
	// 				</button>
	// 				<h5>Contact My Health Educator / Client</h5>
	// 				<p>
	// 					Description of what deactivating means and how it differs from simply logging out. Lorem ipsum dolor sit amet,
	// 					consectetur adipiscing elit. Cras id neque sit amet ante mollis gravida. In velit massa, vehicula in dolor vel,
	// 					varius viverra risus.
	// 				</p>
	// 				<textarea name="message" placeholder="Message"></textarea>
	// 				<Container type="row">
	// 					<Button>Send Message</Button>
	// 					<Button btnStyle="secondary" onClick={setMessageModalVisible}>
	// 						Cancel
	// 					</Button>
	// 				</Container>
	// 			</div>
	// 		</div>
	// 	</>
	// );
};

export default Nav;
