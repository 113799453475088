// import localforage from 'localforage';
// import { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { Breadcrumbs, Icon } from '../index';
import './PageHeader.scss';

const PageHeader = ({ title, icon, subhead, currentClient, children }) => {
	const { path } = useRouteMatch();

	const extraClasses = path === '/modules/:slug/:lessonSlug/:sectionSlug' ? 'PageHeader--lesson-section' : '';
	// console.log(params.hasOwnProperty('lessonSlug'));

	// useEffect(() => {
	// 	const fetchLocalCourse = localforage.getItem('course')
	// }, []);

	return (
		<div className={`PageHeader ${extraClasses}`}>
			<div className="wrapper">
				<Breadcrumbs currentTitle={title} currentClient={currentClient} />
				<div className="PageHeader__top-bar">
					{title && (
						<h1>
							{icon && (
								<span className="icon btn--icon bg--dark">
									<Icon name={icon} />
								</span>
							)}
							{title}
							{subhead && <span className="subhead">{subhead}</span>}
						</h1>
					)}
					{children}
				</div>
			</div>
		</div>
	);
};

export default PageHeader;
