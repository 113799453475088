import './Playground.scss';

const Playground = ({ currentUser }) => {
	let u = currentUser;

	delete u.clients;

	return (
		<div className="Playground">
			<div className="wrapper">
				<h1>Playground</h1>
				<p>{JSON.stringify(u)}</p>
			</div>
		</div>
	);
};

export default Playground;
