import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ProvideAuth } from './hooks/useAuth';
import App from './App';

const rootElement = document.getElementById('root');
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter basename="/build">
			<QueryClientProvider client={queryClient}>
				<ProvideAuth>
					<App />
				</ProvideAuth>
			</QueryClientProvider>
		</BrowserRouter>
	</React.StrictMode>,
	rootElement
);

// serviceWorkerRegistration.register();
