import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
// import { useQueryClient } from 'react-query';
// import { MapInteractionCSS } from 'react-map-interaction';
import { PageHeader, ImageUpload, FormInput, FormToggle, FormSelect, Button } from '../../components';
import { useMe } from '../../hooks/useMe';
import { useUpdateProfile } from '../../hooks/useUpdateProfile';
import { useUpdateClientProfile } from '../../hooks/useUpdateClientProfile';
import { useClients } from '../../hooks/useClients';
import { stateSelectOptions, asyncUploadImage } from '../../utils/misc';

import './EditUser.scss';

const EditUser = ({ pageTitle, match, ...props }) => {
	const user = useMe();
	// const queryClient = useQueryClient();
	const { isLoading, error, data: clients } = useClients();
	const [uploadInProgress, setUploadInProgress] = useState(false);
	const [errorMessages, setErrorMessages] = useState([]);
	// const [imageInteractionValue, setImageInteractionValue] = useState({ scale: 1, translation: { x: 0, y: 0 } });
	const updateProfileMutation = useUpdateProfile();
	const updateClientProfileMutation = useUpdateClientProfile(user.clients ? user.clients : []);
	const history = useHistory();
	const formRef = useRef();
	const addressRef = useRef();
	const imgUploadRef = useRef();
	const mainCardTitle = `${props.location.pathname.indexOf('/my-profile') > -1 ? 'My' : 'Client'} details`;
	const addressCardTitle = `${props.location.pathname.indexOf('/my-profile') > -1 ? 'My' : 'Client'} address`;
	let userProfile = user.profile;
	let client = null;

	// useEffect(() => {
	// 	return () => {
	// 		if (user.roles.indexOf('participant') < 0 && props.location.pathname.indexOf('/my-profile') < 0) {
	// 		} else {
	// 			queryClient.invalidateQueries('user');
	// 		}
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	let mutateUser = (payload) => {
		updateProfileMutation.mutateAsync({ user: user, fields: payload }).then((responseUser) => {
			setUploadInProgress(false);

			history.push({
				pathname: '/my-profile',
				state: {
					errors: errorMessages,
				},
			});
		});
	};

	if (user.roles.indexOf('participant') < 0 && props.location.pathname.indexOf('/my-profile') < 0) {
		if (isLoading) return 'Loading...';

		if (error) return 'error: ' + error.message;

		client = clients.find((client) => client.id === +match.params.clientId);

		userProfile = client.profile;

		mutateUser = (payload) => {
			updateClientProfileMutation.mutateAsync({ client: client, fields: payload }).then((response) => {
				setUploadInProgress(false);
				history.push(`/my-clients/${client.id}`);
			});
		};
	}

	const saveClickHandler = async (ev) => {
		formRef.current.reportValidity();

		if (!formRef.current.checkValidity()) return;

		setUploadInProgress(true);

		const payload = {};
		const portraitFile = imgUploadRef.current.files[0];
		const targetEls = [...formRef.current.elements, ...addressRef.current.elements];

		Array.prototype.forEach.call(targetEls, (el) => (payload[el.name] = el.value));

		if (portraitFile) {
			try {
				const attachmentId = await asyncUploadImage(portraitFile);
				if (Number.isInteger(attachmentId)) payload['portrait'] = attachmentId;
			} catch (error) {
				setErrorMessages((prevMessages) => [...prevMessages, error.response.data.message]);
			}
		}

		mutateUser(payload);
	};

	return (
		<div className="EditUser">
			<PageHeader title={pageTitle} icon="user-group" currentClient={client}>
				<Button onClick={saveClickHandler} disabled={uploadInProgress}>
					{uploadInProgress ? 'Uploading...' : 'Save changes'}
				</Button>
			</PageHeader>
			<div className="wrapper">
				<div className="EditClient__card">
					<h5>{mainCardTitle}</h5>
					<div className="EditClient__main">
						<ImageUpload portraitSrc={userProfile.portrait && userProfile.portrait.url} ref={imgUploadRef} />
						<div className="profile-form">
							<form ref={formRef}>
								<FormInput
									type="text"
									id="first_name"
									name="firstname"
									required
									placeholder="First Name"
									label="First Name"
									defaultValue={userProfile.firstname}
								/>
								<FormInput
									type="text"
									id="last_name"
									name="lastname"
									required
									placeholder="Last Name"
									label="Last Name"
									defaultValue={userProfile.lastname}
								/>
								<FormInput
									type="text"
									id="phone_number"
									name="phone_number"
									pattern="[0-9]{10}"
									placeholder="Phone Number"
									label="Phone Number"
									defaultValue={userProfile.phone_number}
								/>
								<FormInput
									type="email"
									id="email"
									name="email"
									required
									placeholder="Email Address"
									label="Email Address"
									defaultValue={userProfile.email}
								/>
								<FormInput
									type="date"
									id="date"
									name="date_of_birth"
									placeholder="Date of Birth"
									label="Date of Birth"
									defaultValue={userProfile.date_of_birth}
								/>
							</form>

							{/* <Cta
								align="right"
								clickHandler={() => {
									setPasswordModalVisible();
									console.log('Launch change password modal');
								}}>
								Change Client Password
							</Cta> */}
						</div>
					</div>
				</div>

				<div className="EditClient__secondary">
					<div className="EditClient__card">
						<h5>{addressCardTitle}</h5>

						<form ref={addressRef}>
							<FormInput
								type="text"
								id="street_address"
								name="street_address"
								placeholder="Street Address"
								label="Street Address"
								defaultValue={userProfile.street_address}
							/>
							<FormInput
								type="text"
								id="aptunit"
								name="aptunit"
								placeholder="Apt / Unit"
								label="Apt / Unit"
								defaultValue={userProfile.aptunit}
							/>
							<FormInput type="text" id="city" name="city" placeholder="City" label="City" defaultValue={userProfile.city} />
							<FormSelect
								id="state"
								name="state"
								label="State"
								defaultValue={userProfile.state}
								options={stateSelectOptions}
							/>
							<FormInput type="text" id="zip" name="zip" placeholder="Zip" label="Zip" defaultValue={userProfile.zip} />
						</form>
					</div>
					{false && (
						<ul className="EditClient__card EditClient__card--bordered-list">
							<li>
								<h5>Notification Options</h5>
								<p>
									Besides receiving alerts on this app and your device as push notifications, you can receive via
									alternative methods.
								</p>
							</li>
							<li>
								<header>
									<h5>Email</h5>
									<FormToggle id="email_notification" />
								</header>
								<p>Get notified via emails on my inbox</p>
							</li>
							<li>
								<header>
									<h5>Text</h5>
									<FormToggle id="text_notification" defaultChecked={true} />
								</header>
								<p>Get notified via SMS text messages on my mobile phone</p>
							</li>
						</ul>
					)}
					{false && (
						<ul className="EditClient__card EditClient__card--bordered-list">
							<li>
								<h5>Lesson Download Options</h5>
								<p>To view images and videos within lessons in high quality, you will need to download the lesson.</p>
							</li>
							<li>
								<header>
									<h5>Download Upcoming</h5>
									<FormToggle id="download_upcoming" />
								</header>
								<p>Upcoming lessons will be downloaded automatically</p>
							</li>
							<li>
								<header>
									<h5>Remove Completed</h5>
									<FormToggle id="remove_completed" defaultChecked={true} />
								</header>
								<p>Completed lessons will be removed after 3 days</p>
							</li>
						</ul>
					)}
				</div>

				<Button onClick={saveClickHandler}>Save changes</Button>
			</div>
		</div>
	);
};

export default EditUser;
