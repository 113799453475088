import { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useCreateClient } from '../../hooks/useCreateClient';
import { useHistory } from 'react-router';
import { useMe } from '../../hooks/useMe';
import { PageHeader, Button, FormInput, ImageUpload, FormSelect } from '../../components';
import { asyncUploadImage, stateSelectOptions } from '../../utils/misc';

import './NewUser.scss';

const NewUser = (props) => {
	const queryClient = useQueryClient();
	const user = useMe();
	const newClientMutation = useCreateClient(user);
	const history = useHistory();
	const [uploadInProgress, setUploadInProgress] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const profileFormRef = useRef();
	const imgUploadInputRef = useRef();
	const pageTitle = props.location.pathname.indexOf('my-clients') > -1 ? 'New client' : 'New user';

	const saveClickHandler = async (ev) => {
		profileFormRef.current.reportValidity();

		if (!profileFormRef.current.checkValidity()) return;

		setUploadInProgress(true);

		const payload = { educatorId: user.id, blogId: localStorage.getItem('blogId') };
		const portraitFile = imgUploadInputRef.current.files[0];

		Array.prototype.forEach.call(profileFormRef.current.elements, (el) => (payload[el.name] = el.value));

		if (portraitFile) {
			const attachmentId = await asyncUploadImage(portraitFile);
			if (Number.isInteger(attachmentId)) payload['portrait'] = attachmentId;
		}

		try {
			const newClientResponse = await newClientMutation.mutateAsync(payload);

			if (newClientResponse.id) {
				queryClient.setQueryData(['client', newClientResponse.id], newClientResponse);

				history.push({
					pathname: `/my-clients/${newClientResponse.id}`,
					state: {
						newClientCreatedResponse: newClientResponse,
					},
				});
			}
		} catch (error) {
			error.response && setErrorMessage(error.response.data.message);
		} finally {
			setUploadInProgress(false);
		}
	};

	return (
		<div className="NewUser">
			<PageHeader title={pageTitle} icon="user-group">
				<Button onClick={saveClickHandler} disabled={uploadInProgress}>
					{uploadInProgress ? 'Uploading...' : 'Create client'}
				</Button>
			</PageHeader>
			<div className="wrapper">
				{errorMessage && (
					<div className="NewUser__errors">
						<h5>{errorMessage}</h5>
						{/* <ul className="errors styled">
							<li>Some error</li>
							<li>Another</li>
						</ul> */}
					</div>
				)}
				<div className="NewUser__card">
					<ImageUpload ref={imgUploadInputRef} />
					<form ref={profileFormRef} className="NewUser__profile-form">
						<FormInput type="text" id="first_name" name="firstname" required placeholder="First Name" label="First Name" />
						<FormInput type="text" id="last_name" name="lastname" required placeholder="Last Name" label="Last Name" />
						<FormInput
							type="text"
							id="phone_number"
							name="phone_number"
							pattern="[0-9]{10}"
							placeholder="Phone Number"
							label="Phone Number"
						/>
						<FormInput type="email" id="email" name="email" required placeholder="Email Address" label="Email Address" />
						<FormInput
							type="text"
							id="street_address"
							name="street_address"
							placeholder="Street Address"
							label="Street Address"
						/>
						<FormInput type="text" id="aptunit" name="aptunit" placeholder="Apt / Unit" label="Apt / Unit" />
						<FormInput type="text" id="city" name="city" placeholder="City" label="City" />
						<FormSelect id="state" name="state" label="State" options={stateSelectOptions} />
						<FormInput type="text" id="zip" name="zip" placeholder="Zip" label="Zip" />

						<FormInput
							type="date"
							id="date"
							name="date_of_birth"
							placeholder="Date of Birth"
							label="Date of Birth"
							defaultValue="1970-01-01"
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default NewUser;
