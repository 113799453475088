import {
	NewUser,
	Client,
	Dashboard,
	LessonIntro,
	LessonOrder,
	Lesson,
	Login,
	Module,
	Modules,
	MyClients,
	MyProfile,
	EditUser,
	Register,
	Educators,
	Educator,
} from './views/index';

export const publicRoutes = [
	{
		title: 'Login',
		classes: 'view--login',
		path: '/login',
		Component: Login,
	},
	{
		title: 'Register',
		classes: 'view--register',
		path: '/register',
		Component: Register,
	},
];

export const privateRoutes = [
	{
		title: 'Dashboard',
		classes: 'view--dashboard',
		path: '/dashboard',
		Component: Dashboard,
	},
	{
		title: 'Modules',
		classes: 'view--modules',
		path: '/modules',
		Component: Modules,
	},
	{
		title: 'My Profile',
		classes: 'view--my-profile',
		path: '/my-profile',
		Component: MyProfile,
	},
	{
		title: 'Settings',
		classes: 'view--my-profile',
		path: '/my-profile/settings',
		Component: EditUser,
	},
	{
		title: 'Module',
		classes: 'view--module',
		path: '/modules/:slug',
		Component: Module,
	},
	{
		title: 'Lesson',
		classes: 'view--lesson-intro',
		path: '/modules/:slug/:lessonSlug',
		Component: LessonIntro,
	},
	{
		title: 'Lesson Section',
		classes: 'view--lesson-section',
		path: '/modules/:slug/:lessonSlug/:sectionSlug',
		Component: Lesson,
	},
];

export const elevatedRoutes = [
	{
		title: 'My Clients',
		classes: 'view--my-clients',
		path: '/my-clients',
		Component: MyClients,
	},
	{
		title: 'New Client',
		classes: 'view--new-client',
		path: '/my-clients/new',
		Component: NewUser,
	},
	{
		title: 'Client Information',
		classes: 'view--my-client',
		path: '/my-clients/:clientId',
		Component: Client,
	},
	{
		title: 'Edit Client Information',
		classes: 'view--my-client-edit',
		path: '/my-clients/:clientId/edit',
		// Component: EditClient,
		Component: EditUser,
	},
	{
		title: 'Lesson Order',
		classes: 'view--my-client-lesson-order',
		path: '/my-clients/:clientId/lesson-order',
		Component: LessonOrder,
	},
];

export const adminRoutes = [
	{
		title: 'Educators',
		classes: 'view--educators',
		path: '/educators',
		Component: Educators,
	},
	{
		title: 'Educator Information',
		classes: 'view--educator',
		path: '/educators/:educatorId',
		Component: Educator,
	},
];
