import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from '..';

import './Modal.scss';

const Modal = ({ isOpen, closeHandler, children }) => {
	const overlayVariants = {
		initial: { opacity: 0 },
		visible: { opacity: 1 },
		exit: { opacity: 0 },
	};

	const contentVariants = {
		initial: { top: '60%' },
		visible: { top: '50%' },
		exit: { top: '60%' },
	};

	useEffect(() => {
		if (isOpen) document.body.classList.add('modal-open');

		return () => document.body.classList.remove('modal-open');
	}, [isOpen]);

	return createPortal(
		<AnimatePresence>
			{isOpen && (
				<motion.div
					initial="initial"
					animate="visible"
					exit="exit"
					variants={overlayVariants}
					onClick={closeHandler}
					className="Modal">
					<motion.div variants={contentVariants} className="Modal__content" onClick={(e) => e.stopPropagation()}>
						<Button className="Modal__close-button" withIcon="close" onClick={closeHandler} />
						{children}
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>,
		document.getElementById('root')
	);
};

export default Modal;
