import axios from '../utils/api';
import localforage from 'localforage';
import { useMutation, useQueryClient } from 'react-query';

export const useUpdateClientProfile = (myClients) => {
	const queryClient = useQueryClient();

	const mutationFn = ({ client, fields }) => {
		for (const fieldName in fields) {
			if (fields.hasOwnProperty(fieldName) && fieldName !== 'portrait' && fieldName !== 'assignedLessons')
				client.profile[fieldName] = fields[fieldName];
		}

		if ('firstname' in fields || 'lastname' in fields)
			client.profile.fullname = client.profile.firstname + ' ' + client.profile.lastname;

		client.lastUpdated = Math.floor(Date.now() / 1000);

		if (fields.hasOwnProperty('date_of_birth')) fields['date_of_birth'] = fields['date_of_birth'].replace(/-/g, '');

		if (fields.hasOwnProperty('assignedLessons')) client.assignedLessons = fields['assignedLessons'];

		return navigator.onLine
			? axios
					.post(`/wp-json/wp/v2/user/${client.id}/update`, { lastUpdated: client.lastUpdated, fields: fields })
					.then(({ data: updatedClient }) => {
						console.log(fields, updatedClient);

						return updatedClient;
					})
			: client;
	};

	return useMutation(mutationFn, {
		onSuccess: (updatedClient) =>
			localforage.getItem('clients').then((clients) => {
				const clientIndex = clients.findIndex((c) => c.id === updatedClient.id);

				clients[clientIndex] = updatedClient;

				localforage.setItem('clients', clients).then((c) => {
					const myClientIndex = myClients.findIndex((myClient) => myClient.id === updatedClient.id);

					// if updated client also belongs to user, update it in their list too
					if (myClientIndex > -1)
						localforage.getItem('user').then((me) => {
							me.clients[myClientIndex] = updatedClient;

							localforage.setItem('user', me).then((user) => {
								queryClient.invalidateQueries('user');
							});
						});

					queryClient.invalidateQueries('clients');
				});
			}),
		onError: (error) => {
			error.response && console.warn('error in mutation: ', error.response.data.message);
		},
	});
};
