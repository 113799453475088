import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { Icon } from '..';

import './TableOfContents.scss';

// Health educator can navigate to sections, participant only sequentially

const TableOfContents = ({ sections, currentSectionIndex }) => {
	const { params } = useRouteMatch();

	const getLinkClass = (idx) => {
		let c = 'link';

		if (idx === currentSectionIndex) c += ' viewing';
		if (idx < currentSectionIndex) c += ' viewed';

		return c;
	};

	return (
		<div className="TableOfContents">
			<h5 className="TableOfContents__title">Table of Contents</h5>

			{sections && (
				<ul className="TableOfContents__list">
					{sections.map((section, idx) => {
						return (
							<li key={idx}>
								<Link to={`/modules/${params.slug}/${params.lessonSlug}/${section.slug}`} className={getLinkClass(idx)}>
									<span className="icon link__icon">
										<Icon name="check" />
									</span>
									<span className="title link__title">{section.section_title}</span>
								</Link>
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};

export default TableOfContents;
