import { createContext, useContext } from 'react';

const meContext = createContext();

export function ProvideMe({ currentUser, children }) {
	return <meContext.Provider value={currentUser}>{children}</meContext.Provider>;
}

export const useMe = () => {
	return useContext(meContext);
};
