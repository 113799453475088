import { useState, useEffect } from 'react';
import localforage from 'localforage';
import { Cta, MiniProgressCard, ColumnCard, ProfileCard } from '../../components';

export const ParticipantSection = ({ user }) => {
	const [filteredAssignedLessons, setFilteredAssignedLessons] = useState([]);
	const [localUser, setLocalUser] = useState(null);

	useEffect(() => {
		console.log('checking participant lessons');
		localforage.getItem('user').then((u) => {
			let parsedLessons = u.assignedLessons
				.filter((assignedLesson) => assignedLesson.active)
				.filter((al) => {
					const lessonInProgress = u.progress.lessons.find((l) => l.slug === al.slug);

					return !lessonInProgress?.complete;
				});

			// console.log(u.progress.lessons);

			setLocalUser(u);
			setFilteredAssignedLessons(parsedLessons);
		});
	}, []);

	return (
		<div className="wrapper participant">
			<h5 className="subhead">{filteredAssignedLessons.length > 0 ? 'Upcoming Tasks' : 'No upcoming tasks.'}</h5>

			<div className="flexgrid">
				<div className="flexgrid__main">
					{filteredAssignedLessons.map((l, idx) => (
						<ColumnCard
							key={idx}
							image={
								l.metadata.lesson_intro.lesson_intro_participant_content.image &&
								l.metadata.lesson_intro.lesson_intro_participant_content.image.url
							}
							col2Content={
								<>
									<h5 className="card__category">{l.moduleTitle}</h5>
									<h3 className="card__title">{l.title}</h3>
									{l.metadata.excerpt && <div dangerouslySetInnerHTML={{ __html: l.metadata.excerpt }}></div>}

									<Cta route={`/modules/${l.moduleSlug}/${l.slug}`}>Start Lesson</Cta>
								</>
							}
							icon="book"
							withPadding={l.metadata.lesson_intro.lesson_intro_participant_content.image ? true : false}
						/>
					))}
				</div>
				<div>
					{user.healthEducators[0] && (
						<ProfileCard title="My Health Educator" userProfile={user.healthEducators[0].profile} abridged />
					)}

					<h4 className="group-title h5">My Progress</h4>
					<ul className="Dashboard__my-progress-list">
						{localUser &&
							[]
								.concat(localUser.progress.lessons)
								.sort((a, b) => (a.complete < b.complete ? 1 : -1))
								.map((lesson, idx) => (
									<li key={`lesson-${lesson.slug}-${idx}-progress-card`}>
										<MiniProgressCard lessonInProgress={lesson} />
									</li>
								))}
					</ul>
				</div>
			</div>
		</div>
	);
};
