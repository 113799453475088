import { Icon, DefaultPortrait } from '..';

import './ProgressBar.scss';

const ProgressBar = ({ progressPercentage, completeTime, clientData }) => {
	const CompleteWithClientData = ({ portrait, by }) => (
		<>
			<div className="ProgressBar__portrait">
				{portrait ? <img src={portrait} alt="" /> : <DefaultPortrait />}
				<span className="complete-icon">
					<Icon name="check" />
				</span>
			</div>
			<div className="ProgressBar__complete-text" style={{ flex: 1 }}>
				<span>Completed by:</span>
				<h5>{by}</h5>
			</div>
		</>
	);

	return (
		<div className={`ProgressBar ${completeTime ? 'ProgressBar--complete' : ''}`}>
			{!completeTime && (
				<div className="ProgressBar__bar">
					<div className="track" style={{ width: progressPercentage + '%' }}></div>
					<div className="handle" style={{ left: progressPercentage + '%' }}></div>
				</div>
			)}

			{!clientData && completeTime && (
				<span className="complete-icon">
					<Icon name="check" />
				</span>
			)}

			{completeTime &&
				(clientData ? (
					<CompleteWithClientData portrait={clientData.portrait?.sizes?.thumbnail} by={clientData.by} />
				) : (
					<span style={{ flex: 1 }}>Complete</span>
				))}

			{completeTime && <span className="since">{completeTime}</span>}
		</div>
	);
};

export default ProgressBar;
