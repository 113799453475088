import { Icon } from '..';
import './SortableLessonCard.scss';
import { useCourse } from '../../hooks/useCourse'

const SortableLessonCard = ({ moduleTitle, lessonTitle, type, innerRef, clickHandler, ...rest }) => {
	const getLessonStatus = () => {
		switch (type) {
			case 'active':
				return {
					title: 'Active',
					iconClass: 'icon active',
					buttonLabel: 'Complete',
				};

			case 'inactive':
				return {
					title: 'Inactive',
					iconClass: 'icon inactive',
					buttonLabel: 'Active',
				};

			case 'complete':
				return {
					title: 'Complete',
					iconClass: 'icon complete',
					buttonLabel: 'Inactive',
				};

			default:
				return {
					title: 'Active',
					iconClass: 'icon active',
					buttonLabel: 'Complete',
				};
		}
	};

	const lessonStatus = getLessonStatus();

	const course = useCourse();
	const allModules = (course.data.modules);
	const moduleIndex = allModules.findIndex(module => module.title === moduleTitle);

	return (
		<div className="SortableLessonCard" ref={innerRef} {...rest}>
			<Icon name="draggable" />

			<div className="SortableLessonCard__info">
				<h6>Module {moduleIndex+1} - {moduleTitle}</h6>
				<h5>{lessonTitle}</h5>
			</div>

			<div className="SortableLessonCard__status">
				{lessonStatus.title}
				<span className={lessonStatus.iconClass}>
					<Icon name="check" />
				</span>
			</div>

			<button className="Button SortableLessonCard__button" onClick={clickHandler} card-type={type} tabindex="0">
				Mark as {lessonStatus.buttonLabel}
			</button>			
		</div>
	);
};

export default SortableLessonCard;
