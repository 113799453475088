import { Icon } from '../';

import './Button.scss';

const Button = ({ btnStyle, withIcon, className, children, ...props }) => {
	let computedClasses = 'Button';

	if (btnStyle) computedClasses += ` Button--${btnStyle}`;
	if (withIcon) computedClasses += ' Button--with-icon';
	if (className) computedClasses += ` ${className}`;

	return (
		<button className={computedClasses} {...props}>
			{children}
			{withIcon && (
				<span className="Button__icon">
					<Icon name={withIcon} />
				</span>
			)}
		</button>
	);
	// return (
	// 	<button
	// 		className={`btn btn--${props.btnStyle} ${props.className ? props.className : ''}`}
	// 		onClick={props.clickHandler}
	// 		disabled={props.disabled}>
	// 		{props.label}
	// 		{props.children}
	// 	</button>
	// );
};

export default Button;
