import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { Icon } from '..';
import { useMe } from '../../hooks/useMe';

import './Breadcrumbs.scss';

const Breadcrumbs = ({ currentTitle, currentClient }) => {
	const match = useRouteMatch();
	const user = useMe();

	const makeTitle = (slug) => {
		return slug.replace(/-/g, ' ').replace(/\b[a-z]/g, function () {
			return arguments[0].toUpperCase();
		});
	};

	const crumbs = match.url
		.split('/')
		.filter((p) => p)
		.map((p, i, arr) => {
			let link = '';

			for (let o = 0; o <= i; o++) {
				link += '/' + arr[o];
			}

			if (arr.length >= 2) {
				let crumbTitle = makeTitle(p);

				switch (arr[0]) {
					case 'educators':
						if (!isNaN(+p)) {
							const educator = user.educators.find((educator) => educator.id === +p);

							crumbTitle = educator.profile.fullname;
						}
						break;

					case 'my-clients':
						if (!isNaN(+p) && currentClient) crumbTitle = currentClient.profile.fullname;
						break;

					default:
						crumbTitle = makeTitle(p);
				}

				return { title: crumbTitle, link: link };
			}

			return { title: makeTitle(p), link: link };
		});

	return crumbs.length > 1 ? (
		<ul className="Breadcrumbs">
			{crumbs.map((crumb, idx) => (
				<li key={`fs-bc-${idx}`} className={idx === crumbs.length - 1 ? 'last' : undefined}>
					<Link to={crumb.link}>
						<span>{crumb.title}</span>
						<Icon name="chevron-right" />
					</Link>
				</li>
			))}
		</ul>
	) : null;
};

export default Breadcrumbs;
