import { useState, useEffect, useRef } from 'react';
import localforage from 'localforage';
import { Link } from 'react-router-dom';
import { ColumnCard, PageHeader, MiniProgressCard, ProfileCard } from '../../components';
import { myProfileMe } from '../../utils/misc';

import './MyProfile.scss';

const MyProfile = ({ pageTitle, currentUser }) => {
	const profileCardLastColumnContent = useRef(null);

	const [localUser, setLocalUser] = useState(false);

	useEffect(() => {
		// console.log('current user changed', currentUser);
		localforage.getItem('user').then((u) => {
			setLocalUser(u);

			profileCardLastColumnContent.current =
				u.roles.indexOf('participant') > -1 && u.healthEducators[0] ? (
					<ProfileCard title="My Health Educator" userProfile={u.healthEducators[0].profile} abridged cardless big />
				) : null;
		});
	}, [currentUser]);

	if (!localUser) return 'Loading...';

	return (
		<div className="MyProfile">
			<PageHeader title={pageTitle} icon="user">
				<Link className="btn btn--secondary btn--settings" to="/my-profile/settings">
					Settings
				</Link>
			</PageHeader>

			<div className="wrapper">
				<ColumnCard
					image={localUser.profile.portrait && localUser.profile.portrait.url}
					col2Content={myProfileMe(localUser.profile)}
					col3Content={profileCardLastColumnContent.current}
				/>

				{localUser.progress && (
					<>
						<h5 className="h5">My Progress</h5>

						<ul className="MyProfile__progress-grid">
							{[]
								.concat(localUser.progress.lessons)
								.sort((a, b) => (a.complete < b.complete ? 1 : -1))
								.map((lesson, idx) => {
									return (
										<li key={`lesson-progress-${idx}-card`}>
											<MiniProgressCard lessonInProgress={lesson} />
										</li>
									);
								})}
						</ul>
					</>
				)}
			</div>
		</div>
	);
};

export default MyProfile;
