import { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import localforage from 'localforage';
import { ReactQueryDevtools } from 'react-query/devtools';
import axios from '../../utils/api';
import { getRoutesByRoles } from '../../utils/misc';
import { Layout, Playground } from '..';

import './PrivateApp.scss';

const PrivateApp = ({ currentUser }) => { 
	const [currentRoutes, setCurrentRoutes] = useState([])
	
	useEffect(() => {
		console.log('PrivateApp component mounted.');
		console.log(currentUser.roles)

		const onlineListener = window.addEventListener('online', (ev) => {
			const userInitialLastUpdated = +localStorage.getItem('userInitialLastUpdated');

			if (!currentUser) return;

			// App is back online; we check if there are any updates to the user by comparing
			// the initial lastUpdated timestamp to the current one in local storage
			// before firing a call to the API
			localforage.getItem('user').then((latestUser) => {
				if (latestUser.lastUpdated > userInitialLastUpdated) {
					console.log('User has been updated offline, syncing to the DB...');
					axios
						.post(`/wp-json/wp/v2/user/${currentUser.id}/update`, {
							lastUpdated: latestUser.lastUpdated,
							fields: { ...latestUser.profile, progress: latestUser.progress },
						})
						.then(({ data: updatedUser }) => {
							localStorage.removeItem('lastSynced');
							localforage.setItem('user', updatedUser).then((u) => {
								console.log('Syncing finished.');
							});
						});
				} else {
					console.log('userInitialLastUpdated matches the current lastUpdated on the user, no need to sync the DB');
				}
			});

			// Looping through potential updated users during view as mode
			localforage.getItem('viewAsHistory').then((viewAsHistory) => {
				if (!viewAsHistory) return false;

				console.log('viewas users have been updated offline, Syncing to the DB...');

				let updateCount = 0;

				viewAsHistory.forEach((viewAsHistoryItem) => {
					console.log(`Syncing user ${viewAsHistoryItem.id} to the DB...`);
					axios
						.post(`/wp-json/wp/v2/user/${viewAsHistoryItem.id}/update`, {
							lastUpdated: viewAsHistoryItem.lastUpdated,
							fields: { ...viewAsHistoryItem.profile, progress: viewAsHistoryItem.progress },
						})
						.then(({ data: updatedUser }) => {
							console.log(`User ${viewAsHistoryItem.id} synced.`);
							updateCount++;

							if (updateCount === viewAsHistory.length) localforage.removeItem('viewAsHistory');
						});
				});
			});
		});

		const offlineListener = window.addEventListener('offline', (ev) => {
			console.log('app is offline');
			localStorage.setItem('lastSynced', Math.floor(Date.now() / 1000));
		});

		return () => {
			window.removeEventListener('online', onlineListener);
			window.removeEventListener('offline', offlineListener);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setCurrentRoutes(getRoutesByRoles(currentUser.roles));
	}, [currentUser]);
	
	// const currentRoutes = getRoutesByRoles(currentUser.roles);

	return (
		<Layout currentRoutes={currentRoutes} userRoles={currentUser.roles} currentUser={currentUser}>
			<Switch>
				{currentRoutes.map(({ path, Component, title }) => {
					return (
						<Route
							key={path}
							path={path}
							exact
							render={(props) => <Component {...props} pageTitle={title} currentUser={currentUser} />}
						/>
					);
				})}
				<Route path="/playground" exact render={(props) => <Playground currentUser={currentUser} {...props} />} />
				<Route exact path="*">
					<Redirect to="/dashboard"></Redirect>
				</Route>
			</Switch>
			<ReactQueryDevtools initialIsOpen />
		</Layout>
	);
};

export default PrivateApp;
