import { Icon, DefaultPortrait } from '..';
import './ColumnCard.scss';

const ColumnCard = ({ image, icon, col2Content, col3Content, withPadding }) => {
	return (
		<div className="ColumnCard">
			{icon && <Icon className="ColumnCard__icon secondary" name={icon} />}
			<div className={`ColumnCard__image ${withPadding ? 'ColumnCard__image--padded' : ''}`}>
				{image ? (
					<div className="ColumnCard__image__inner" style={{ backgroundImage: `url(${image})` }}></div>
				) : (
					<DefaultPortrait />
				)}
			</div>
			<div className="ColumnCard__col">{col2Content}</div>
			{col3Content && <div className="ColumnCard__col">{col3Content}</div>}
		</div>
	);
};

export default ColumnCard;
