import { AnimatePresence, motion } from 'framer-motion';

import './SpinnerLoader.scss';

const SpinnerLoader = ({ isVisible }) => {
	return (
		<AnimatePresence>
			{isVisible && (
				<motion.div className="spinner-overlay" animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1.6 }}>
					<svg className="spinner" viewBox="0 0 50 50">
						<circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
					</svg>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default SpinnerLoader;
