import { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { publicRoutes } from '../../routes';

import './PublicApp.scss';

const PublicApp = (props) => {
	useEffect(() => {
		document.body.classList.remove('theme--sky');
		document.body.classList.add('theme--earth');
	}, []);

	return (
		<Switch>
			{publicRoutes.map(({ path, Component }) => {
				return <Route key={path} path={path} exact render={(props) => <Component {...props} />} />;
			})}
			<Route exact path="*">
				<Redirect to="/login"></Redirect>
			</Route>
		</Switch>
	);
};

export default PublicApp;
