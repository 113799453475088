export const sortableReducer = (state, action) => {
	const itemsCopy = [...state.items];

	switch (action.type) {
		case 'MOVE_ITEM':
			itemsCopy.splice(action.payload.toIndex, 0, itemsCopy.splice(action.payload.fromIndex, 1)[0]);

			return { ...state, items: itemsCopy };

		case 'REMOVE_ITEM':
			itemsCopy.splice(action.payload.targetIndex, 1);

			return { ...state, items: itemsCopy };

		case 'ADD_ITEM':
			itemsCopy.splice(action.payload.targetIndex, 0, action.payload.newItem);

			return { ...state, items: itemsCopy };

		default:
			return state;
	}
};
