import { useHistory } from 'react-router';
import { Icon } from '..';

import './Cta.scss';

const Cta = ({ route, icon, align, clickHandler, children }) => {
	const history = useHistory();

	const computedClickHandler = route ? (ev) => history.push({ pathname: route }) : clickHandler;

	return (
		<div className={`Cta ${align ? align : undefined}`}>
			<button className="a btn--link" onClick={computedClickHandler}>
				{children}
				<span className="icon">
					<Icon name={icon ? icon : `arrow-right-circle`} />
				</span>
			</button>
		</div>
	);
};

export default Cta;
