import { useEffect, useRef, useState } from 'react';
import { Header, Nav, Button, OfflineNotifications } from '..';
import { useViewAs } from '../../hooks/useViewAs';

import './Layout.scss';

const Layout = ({ currentRoutes, userRoles, children, currentUser }) => {
	const { viewAsUser, exitViewAsMode } = useViewAs();
	const [isOffline, setIsOffline] = useState(false);
	const [isViewingAs, setIsViewingAs] = useState(false);
	const notificationsRef = useRef(null);

	const adjustBodyPadding = () => {
		const notificationsDimensions = notificationsRef.current.getBoundingClientRect();

		document.body.style.paddingBottom = Math.floor(notificationsDimensions.height) + 40 + 'px';
	};

	useEffect(() => {
		if (notificationsRef.current) adjustBodyPadding();
	}, []);

	useEffect(() => {
		if (notificationsRef.current) adjustBodyPadding();

		if (viewAsUser) {
			setIsViewingAs(true);
		} else {
			setIsViewingAs(false);
		}

		// eslint-disable-next-line
	}, [viewAsUser]);

	return (
		<div className="Layout">
			<Header userRoles={currentUser.roles} isViewingAs={isViewingAs} />
			<Nav
				currentRoutes={currentRoutes}
				userRoles={currentUser.roles}
				className={`${isOffline ? 'isOffline' : ''} ${viewAsUser ? 'isViewingAs' : ''}`}
			/>
			{children}
			<ul className="Layout__notifications-list" ref={notificationsRef}>
				<OfflineNotifications offlineSetter={setIsOffline} />
				{viewAsUser && (
					<li className="Layout__notification Layout__notification--viewAs">
						<span>
							You are viewing as{' '}
							<b>{viewAsUser.profile.fullname.trim() === '' ? viewAsUser.profile.username : viewAsUser.profile.fullname}</b>
						</span>
						<Button
							btnStyle="link"
							withIcon="close"
							onClick={() => {
								exitViewAsMode();
							}}>
							Exit
						</Button>
					</li>
				)}
			</ul>
		</div>
	);
};

export default Layout;
