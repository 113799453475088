import { useEffect, useState } from 'react';
import { useCourse } from './useCourse';
import { useClient } from './useClient';
import { localforage } from 'localforage'

export const useLessonOrderData = (clientId, educatorTrainedModules) => {
	const [lessonDataLoading, setLessonDataLoading] = useState(true);
	const [lessonOrderData, setLessonOrderData] = useState(null);
	const { isLoading: courseLoading, data: course } = useCourse();
	const { isLoading: clientLoading, data: client } = useClient(clientId);

	function filterTrainedModules(lesson) {
		const moduleTitle = lesson.moduleTitle;
		const trainedModules = educatorTrainedModules;
  		return trainedModules.includes(moduleTitle);
	}

	useEffect(() => {
		if (!courseLoading && !clientLoading) {

			const courseLessons = course.modules.reduce((acc, curr) => {
				return [...acc, ...curr.lessons];
			}, []);

			
			// then use client data to assign active/inactive/complete states and return lessons

			// only use lessons that health educators are trained in
			const courseLessonsTrained = courseLessons.filter(filterTrainedModules);

			//check against client data to see if lesson is unassigned / inactive
			const unassignedLessons = courseLessonsTrained.filter((courseLesson) => {
				return !client.assignedLessons.find((l) => l.id === courseLesson.id);
			});

			//check against client to data to see if lesson has been assined / active
			const _assignedLessons = courseLessonsTrained.filter((courseLesson) => {
				return client.assignedLessons.findIndex((l) => l.id === courseLesson.id) !== -1
			});

			//check if assigned lessons are complete, set complete state if so and return
			const assignedLessons = _assignedLessons.map(lesson => {
				
				const clientLesson = client.assignedLessons.find((l) => l.id === lesson.id);
				lesson.active = clientLesson.active;

				const inProgress = client.progress.lessons.filter((progressLesson) => {
					return progressLesson.slug == lesson.slug;
				});

				if(inProgress[0]){
					const isComplete = inProgress[0].progressPercentage == 100;
					lesson.complete = isComplete;
				}
				return lesson;
			});

			setLessonOrderData({ client: client, assigned: assignedLessons, unassigned: unassignedLessons });
			setLessonDataLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courseLoading, clientLoading]);

	return [lessonDataLoading, lessonOrderData];
};
