import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import localforage from 'localforage';
import { useQueryClient } from 'react-query';

const authContext = createContext();

export function ProvideAuth({ children }) {
	const auth = useProvideAuth();
	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
	return useContext(authContext);
};

function useProvideAuth() {
	const queryClient = useQueryClient();
	const [authUser, setAuthUser] = useState(null);
	const [authToken, setAuthToken] = useState(null);
	const [authLoading, setAuthLoading] = useState(true);

	useEffect(() => {
		const fetchLocalData = async () => {
			const user = await localforage.getItem('user');
			const token = await localforage.getItem('token');

			if (token) setAuthToken(token);
			if (user) setAuthUser(user);

			setAuthLoading(false);
		};

		fetchLocalData();
	}, []);

	const signin = async (username, password) => {
		try {
			const { data: appData } = await axios.post('/wp-json/jwt-auth/v1/token', { username: username, password: password });
			const minRoles = appData.user.roles.filter((x) => ['participant', 'health-educator', 'administrator', 'affiliate'].includes(x));

			if (minRoles.length === 0) return { status: 'wrongRoles' };

			// Using local storage to simplify things (keep it synchronous)
			localStorage.setItem('userInitialLastUpdated', appData.user.lastUpdated);
			localStorage.setItem('blogId', appData.blogId);

			const promises = [
				localforage.setItem('token', appData.token),
				localforage.setItem('course', appData.course),
				localforage.setItem('user', appData.user),
			];

			if (appData.clients) promises.push(localforage.setItem('clients', appData.clients));

			Promise.all(promises).then((r) => {
				queryClient.setQueryData('course', appData.course);
				queryClient.setQueryData('user', appData.user);
				queryClient.setQueryData('token', appData.token);
				queryClient.setQueryData('currentUser', appData.user);

				setAuthUser(appData.user);
				setAuthToken(appData.token);

				if (appData.clients) queryClient.setQueriesData('clients', appData.clients);

				setAuthLoading(false);
			});
		} catch (error) {
			return error.response;
		}
	};

	const signout = async () => {
		await Promise.all([localforage.removeItem('previousUser'), localforage.removeItem('token')]);
		queryClient.invalidateQueries('token');
		queryClient.invalidateQueries('currentUser');
		setAuthUser(null);
		setAuthToken(null);
		setAuthLoading(false);
	};

	const signup = () => {};

	return { authLoading: authLoading, authUser: authUser, authToken: authToken, signin, signout, signup };
}
