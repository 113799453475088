import './Icon.scss';

const Icon = (props) => {
	const reqSvgs = require.context('!@svgr/webpack!../../assets/icons', true, /\.svg$/);

	const flagMap = reqSvgs.keys().reduce((images, path) => {
		const key = path.substring(path.lastIndexOf('/') + 1, path.lastIndexOf('.'));

		images[key] = reqSvgs(path).default;

		return images;
	}, {});

	const Icon = flagMap[props.name];

	if (!Icon) return false;

	return <Icon {...props} />;
};

export default Icon;
