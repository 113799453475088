import { Button, FormInput } from '../../components';
import logo1x from '../../assets/logo-full.png';
import logo2x from '../../assets/logo-full@2x.png';
import { useState } from 'react';

import './Register.scss';

const Register = (props) => {
	const totalSteps = 3;
	const [x, setX] = useState(0);

	const advanceClickHandler = () => {
		setX((x) => (x < totalSteps ? x + 1 : x));
	};

	return (
		<div className="Register">
			<div className="Register__image" style={{ backgroundImage: 'url(https://source.unsplash.com/900x600?family)' }}></div>
			<div className="Register__form">
				<ul className="progress">
					{[...Array(totalSteps)].map((el, idx) => (
						<li key={`progress-step-${idx}`} className={`progress__step ${idx <= x ? 'completed' : ''}`}></li>
					))}
				</ul>
				<ul className="screens" style={{ transform: `translateX(-${(x * 100) / 3}%)` }}>
					<li>
						<img className="logo" width="176" src={logo1x} srcSet={`${logo1x} 1x, ${logo2x} 2x`} alt="Family spirit logo" />
						<h2 className="h2">Welcome, Jennifer!</h2>
						<p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam nesciunt itaque praesentium voluptatum
							libero! Quos architecto nihil quod ducimus itaque omnis quidem fugiat consequatur alias, voluptate doloremque
							aperiam velit dolor?
						</p>
						<Button onClick={advanceClickHandler}>Continue</Button>
					</li>
					<li>
						<h2 className="h2">What's your email address?</h2>
						<p>Lorem ipsum dolor sit amet consectetur elit ne facer adipciscing</p>
						<FormInput label="Email" type="text" name="email" id="email" placeholder="Email" autoComplete="email" />
						<Button onClick={advanceClickHandler}>Continue</Button>
					</li>
					<li>
						<h2 className="h2">Lastly, set your account password</h2>
						<p>Lorem ipsum dolor sit amet consectetur elit ne facer adipciscing</p>
						<FormInput
							label="Password"
							type="password"
							name="password"
							id="password"
							placeholder="Password"
							autoComplete="password"
						/>
						<Button>Finish</Button>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Register;
