import { Icon, ImageCard, SimpleForm } from '..';

import './LessonSection.scss';

const LessonSection = ({ section, user, activityDataChangeHandler, previousActivityData, lessonSlug }) => {
	return (
		<div className="LessonSection">
			{/* <p>{JSON.stringify(previousActivityData)}</p> */}
			{user.roles.indexOf('participant') < 0 && section.section_he && (
				<div className="he">
					<h3 className="Lesson__section__title">{section.section_title}</h3>
					<h3 className="he__title">
						<Icon name="user" className="primary"></Icon>
						<span>Health Educator Section</span>
					</h3>
					{section.section_he.length > 0 &&
						section.section_he.map((block, idx) => {
							if (block.is_formatted_callout_with_icon) {
								return (
									<div className="he__block block block--icon" key={idx}>
										<h5 className="block__title">
											<span className="icon btn--icon bg-green color-white">
												<Icon name={block.callout_type.value} />
											</span>
											{block.callout_type.label}
										</h5>
										<div className="block__content" dangerouslySetInnerHTML={{ __html: block.content }}></div>
									</div>
								);
							} else {
								return (
									<div className="he__block block" key={idx}>
										<div
											className={`flexible-content flexible-content--${
												block.inject_media && block.inject_media.media_position
											}`}>
											{block.inject_media && block.inject_media.image && (
												<div
													className={`block__injected-media block__injected-media--${block.inject_media.media_type}`}>
													{block.inject_media.media_type === 'default' ? (
														<img
															src={block.inject_media.image.url}
															alt={block.inject_media.image.description}
														/>
													) : (
														<>
															{/* <ImageZoom image={block.inject_media.image} /> */}
															<ImageCard imageObject={block.inject_media.image} />
														</>
													)}
												</div>
											)}
											<div className="block__content" dangerouslySetInnerHTML={{ __html: block.content }}></div>
										</div>
									</div>
								);
							}
						})}
				</div>
			)}

			{section.section_participant.length > 0 && (
				<div className="participant-section">
					{user.roles.indexOf('participant') < 0 && (
						<div className="he">
							<h3 className="he__title">
								<Icon name="user-group" className="primary"></Icon>
								<span>Participant Section</span>
							</h3>
						</div>
					)}
					{user.roles.indexOf('participant') > -1 && <h3 className="lesson-section__title">{section.section_title}</h3>}
					{section.section_participant.map((block, idx) => {
						if (block.is_formatted_callout_with_icon) {
							return (
								<div className="he__block block block--icon" key={idx}>
									<h5 className="block__title">
										<span className="icon btn--icon bg-green color-white">
											<Icon name={block.callout_type.value} />
										</span>
										{block.callout_type.label}
									</h5>
									<div className="block__content" dangerouslySetInnerHTML={{ __html: block.content }}></div>
								</div>
							);
						} else {
							return (
								<div className="he__block block" key={idx}>
									<div
										className={`flexible-content flexible-content--${
											block.inject_media && block.inject_media.media_position
										}`}>
										{block.inject_media && block.inject_media.image && (
											<div
												className={`block__injected-media block__injected-media--${block.inject_media.media_type}`}>
												{block.inject_media.media_type === 'default' ? (
													<img src={block.inject_media.image.url} alt={block.inject_media.image.description} />
												) : (
													<>
														{/* <ImageZoom image={block.inject_media.image} /> */}
														<ImageCard imageObject={block.inject_media.image} />
													</>
												)}
											</div>
										)}
										<div className="block__content" dangerouslySetInnerHTML={{ __html: block.content }}></div>
									</div>
									{section.is_activity && block.form && (
										<SimpleForm
											sectionFormId={`lesson-${lessonSlug}-section-${section.slug}-block-${idx}`}
											fields={block.form.fields}
											updateCurrentDataHandler={activityDataChangeHandler}
											noData={user.roles.indexOf('participant') < 0}
										/>
									)}
								</div>
							);
						}
					})}
				</div>
			)}
		</div>
	);
};

export default LessonSection;
