import { useEffect } from 'react';
import { useQuery } from 'react-query';
import localforage from 'localforage';
import { SpinnerLoader, PublicApp, PrivateApp } from './components';
import { ProvideViewAs } from './hooks/useViewAs';
import { ProvideMe } from './hooks/useMe';
import { useAuth } from './hooks/useAuth';
import { switchTheme } from './utils/misc';

import './scss/App.scss';

const App = () => {
	const { authLoading, authUser, authToken } = useAuth();
	const { isLoading, data: currentUser } = useQuery('currentUser', () => localforage.getItem('user'), { initialData: authUser });

	useEffect(() => {
		if (currentUser) switchTheme(currentUser.roles);
	}, [currentUser]);

	if (authLoading || isLoading) return <SpinnerLoader isVisible={true} />;

	return (
		<div className="FamilySpiritApp">
			{!authUser || !authToken ? (
				<PublicApp />
			) : (
				<ProvideViewAs>
					<ProvideMe currentUser={currentUser}>
						<PrivateApp currentUser={currentUser} />
					</ProvideMe>
				</ProvideViewAs>
			)}
		</div>
	);
};
export default App;
