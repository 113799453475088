import axios from '../utils/api';
import localforage from 'localforage';
import { useMutation, useQueryClient } from 'react-query';

export const useCreateClient = (educator) => {
	const queryClient = useQueryClient();

	const mutationFn = (payload) => axios.post('/wp-json/wp/v2/users/register', payload).then(({ data: newClient }) => newClient);

	return useMutation(mutationFn, {
		onSuccess: (newClient) => {
			educator.clients.push(newClient);

			return localforage.setItem('user', educator).then((user) => {
				queryClient.invalidateQueries('user');
			});
		},
		onError: (error) => error,
	});
};
