import { Link } from 'react-router-dom';
import { getAge, getAddress, formatPhoneNumber } from '../../utils/misc';
import { DefaultPortrait, Icon, Button } from '..';
import { motion } from 'framer-motion';

import './ProfileCard.scss';

const CardWrapper = ({ as = null, className, children }) => {
	switch (as) {
		case 'li':
			return (
				<motion.li layout className={className}>
					{children}
				</motion.li>
			);

		default:
			return (
				<motion.div className={className} layout>
					{children}
				</motion.div>
			);
	}
};

const ProfileCard = ({ userProfile, title, link, abridged, cardless, big, as, healthEducator }) => {

	let classNames = 'ProfileCard';

	classNames += abridged ? ' ProfileCard--abridged' : '';
	classNames += cardless ? ' ProfileCard--cardless' : '';

	return (
		<CardWrapper as={as} className={classNames}>
			{title && <h5 className="ProfileCard__title">{title}</h5>}
			<header className="ProfileCard__header">
				{userProfile.portrait ? (
					<img
						className={big ? 'big' : ''}
						src={userProfile.portrait.sizes ? userProfile.portrait.sizes.thumbnail : userProfile.portrait.url}
						alt={userProfile.fullname}
					/>
				) : (
					<DefaultPortrait type="round" />
				)}
				<span className="name">{userProfile.fullname}</span>
			</header>
			<ul className="ProfileCard__info">
				{userProfile.phone_number && (
					<li>
						<Icon name="phone" className="primary" /> {formatPhoneNumber(userProfile.phone_number)}
					</li>
				)}
				{userProfile.email && (
					<li>
						<Icon name="mail" className="primary" />
						<a target="_blank" rel="noreferrer" href={`mailto:${userProfile.email}`}>
							{userProfile.email}
						</a>
					</li>
				)}
				{!abridged && userProfile.date_of_birth && (
					<li>
						<Icon name="calendar" className="primary" />
						{`${getAge(userProfile.date_of_birth)} years old`}
					</li>
				)}
				{!abridged && userProfile.state && (
					<li>
						<Icon name="location" className="primary" />
						{getAddress(userProfile)}
					</li>
				)}
				{!abridged && healthEducator && (
					<li>
						<Icon name="user" className="primary" />
						Assigned to {healthEducator.profile.fullname}
					</li>
				)}
			</ul>
			{!abridged &&
				(link ? (
					<Link className="Button" to={link}>
						View more information
					</Link>
				) : (
					<Button>View more information</Button>
				))}
		</CardWrapper>
	);
};

export default ProfileCard;
