import { useEffect, useState } from 'react';
import { Button, Icon } from '..';

import './Dropdown.scss';

const Dropdown = (props) => {
	const [isShown, setIsShown] = useState(false);

	useEffect(() => {
		const outsideClickListener = document.addEventListener('click', (ev) => {
			// ev.preventDefault();
			// console.log(ev, ev.target);
			// setIsShown(false);
		});

		return () => {
			document.removeEventListener('click', outsideClickListener);
		};
	}, []);

	return (
		<>
			<Button
				onClick={() => {
					setIsShown((prev) => !prev);
				}}
				btnStyle="icon"
				className={isShown ? 'active' : undefined}>
				<Icon name={props.icon} />
			</Button>

			<div className={`Dropdown ${isShown ? 'Dropdown--shown' : ''}`}>
				<div
					{...props}
					className={`card card--dropdown ${props.className ? props.className : ''}`}
					onClick={() => {
						setIsShown((prev) => !prev);
					}}>
					{props.children}
				</div>
			</div>
		</>
	);
};

export default Dropdown;
