import './DefaultPortrait.scss';

const DefaultPortrait = ({ type = 'default' }) => {
	return (
		<div className={`DefaultPortrait DefaultPortrait--${type}`}>
			<svg width="46px" height="46px" viewBox="0 0 46 46">
				<path
					d="M0,48.6142738 L0,46.8873673 C0,37.3410682 2.33230841,33.5703579 17.2855514,33.5703579 L21.4075047,42.5804047 C21.9185701,43.6973299 23.5053458,43.6973299 24.0164112,42.5804047 L28.1383645,33.5703579 C42.4953645,33.5703579 45.4234579,37.3410682 45.4234579,46.8873673 L45.4234579,48.6142738"
					fill="none"
					className="pathToColor"
					strokeWidth="2"></path>
				<path
					d="M37.032643,14.8735607 C37.032643,23.0877009 30.3732224,29.7471215 22.1590822,29.7471215 C13.9440262,29.7471215 7.28506355,23.0877009 7.28506355,14.8735607 C7.28506355,6.65896262 13.9440262,0 22.1590822,0 C30.3732224,0 37.032643,6.65896262 37.032643,14.8735607 Z"
					fill="none"
					className="pathToColor"
					strokeWidth="2"></path>
				<line x1="15.879114" y1="28.3395393" x2="15.879114" y2="31.1545206"></line>
				<line x1="29.3830561" y1="27.9862355" x2="29.3830561" y2="30.8012168"></line>
				<path
					d="M14.0920336,3.26486542 C14.0920336,3.26486542 20.8863831,12.9164842 33.2375887,12.9164842"
					fill="none"
					className="pathToColor"
					strokeWidth="2"></path>
				<path
					d="M18.7385617,23.3316477 L22.1177299,23.3316477 C23.1064308,23.3316477 24.0951318,23.1928907 25.0261318,22.8617972 C25.8389822,22.5723766 26.7049542,22.1180963 27.2192252,21.4146944"
					fill="none"
					className="pathToColor"
					strokeWidth="2"></path>
			</svg>
		</div>
	);
};

export default DefaultPortrait;
