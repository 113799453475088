import { Icon } from '..';
import './IconButton.scss';

const IconButton = (props) => {
	return (
		<button
			className={`btn btn--icon ${props.className ? props.className : ''}`}
			onClick={props.clickHandler}
			disabled={props.disabled}>
			<Icon className={'icon icon--' + props.icon} name={props.icon}></Icon>
		</button>
	);
};

export default IconButton;
