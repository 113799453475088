import { createContext, useContext, useState, useEffect } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useClients } from './useClients';
import localforage from 'localforage';

const viewAsContext = createContext();

export function ProvideViewAs({ children }) {
	const viewAs = useProvideViewAs();

	return <viewAsContext.Provider value={viewAs}>{children}</viewAsContext.Provider>;
}

export const useViewAs = () => {
	return useContext(viewAsContext);
};

function useProvideViewAs() {
	const queryClient = useQueryClient();
	const { data: clients } = useClients();
	const [viewAsUser, setViewAsUser] = useState(null);

	const clientProgressMutation = useMutation(
		({ newProgress, clientId }) => {
			return localforage.getItem('clients').then((clients) => {
				const clientIndex = clients.findIndex((c) => c.id === clientId);

				if (clientIndex < 0) return clients;

				clients[clientIndex].progress = newProgress;

				return localforage.setItem('clients', clients);
			});
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('clients');
				queryClient.invalidateQueries('client');
			},
		}
	);

	useEffect(() => {
		const checkForPreviousUsers = async () => {
			const previousUser = await localforage.getItem('previousUser');
			const currentUser = await localforage.getItem('user');

			if (previousUser && !viewAsUser) {
				console.log('INITIAL USER CHECK ON VIEW AS FOUND USER, SETTING IT');
				setViewAsUser(currentUser);
				// queryClient.setQueryData('currentUser', currentUser);
			}
		};

		checkForPreviousUsers();

		// 	// localforage.getItem('previousUser').then((previousUser) => {
		// 	// 	console.log('INITIAL USER CHECK ON VIEW AS FOUND USER, SETTING IT');
		// 	// 	if (previousUser) setViewAsUser(previousUser);
		// 	// });

		// 	// Promise.all([localforage.getItem('previousUser'), localforage.getItem('user')]).then(([previousUser, currentUser]) => {
		// 	// 	console.log('initial view as check for user PROMISE DONE HERE');
		// 	// 	if (previousUser && !viewAsUser) {
		// 	// 		console.log('INITIAL USER CHECK ON VIEW AS FOUND USER, SETTING IT');
		// 	// 		setViewAsUser(currentUser);
		// 	// 		queryClient.setQueryData('currentUser', currentUser);
		// 	// 	}
		// 	// });
	}, [viewAsUser, queryClient]);

	const updateViewAsHistory = async (user) => {
		const payload = { id: user.id, lastUpdated: Math.floor(Date.now() / 1000), profile: user.profile, progress: user.progress };
		const localViewAsHistory = await localforage.getItem('viewAsHistory');
		const mutationResult = await clientProgressMutation.mutate({ newProgress: user.progress, clientId: user.id });

		console.log(mutationResult);

		if (!localViewAsHistory) return [payload];

		const existingUserHistoryIndex = localViewAsHistory.findIndex((u) => u.id === user.id);

		if (existingUserHistoryIndex > -1) {
			localViewAsHistory[existingUserHistoryIndex] = payload;
		} else {
			localViewAsHistory.push(payload);
		}
		return localViewAsHistory;
	};

	const updateLocalClients = async (localViewAsHistory, user) => {
		const currentClients = user.clients;

		localViewAsHistory.forEach((historyItem) => {
			const clientIndex = currentClients.findIndex((c) => c.id === historyItem.id);

			if (clientIndex < 0) return;

			currentClients[clientIndex].profile = historyItem.profile;
			currentClients[clientIndex].progress = historyItem.progress;
		});

		return currentClients;
	};

	const switchUser = async (clientId) => {
		const currentUser = await localforage.getItem('user');

		if (clients) {
			const client = currentUser.clients.find((cl) => cl.id === clientId);

			if (!client) {
				console.warn('no client found by that id in the current user :', currentUser, clients);
				return;
			}

			const updatedLocalViewAsHistory = await updateViewAsHistory(client);

			client.healthEducators = [currentUser];

			Promise.all([
				localforage.setItem('previousUser', currentUser),
				localforage.setItem('user', client),
				localforage.setItem('viewAsHistory', updatedLocalViewAsHistory),
			]).then(([u, c, h]) => {
				setViewAsUser(client);
				queryClient.setQueryData('currentUser', client);
			});
		}
	};

	const exitViewAsMode = async () => {
		const currentUser = await localforage.getItem('user');
		const previousUser = await localforage.getItem('previousUser');
		const updatedLocalViewAsHistory = await updateViewAsHistory(currentUser);
		const updatedLocalClients = await updateLocalClients(updatedLocalViewAsHistory, previousUser);

		previousUser.clients = updatedLocalClients;

		Promise.all([
			localforage.removeItem('previousUser'),
			localforage.setItem('user', previousUser),
			localforage.setItem('viewAsHistory', updatedLocalViewAsHistory),
		]).then(() => {
			setViewAsUser(null);
			queryClient.setQueryData('currentUser', previousUser);
		});
	};

	return { viewAsUser, switchUser, exitViewAsMode };
}
