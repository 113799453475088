import './FormInput.scss';

const FormInput = (props) => {
	return (
		<div className="form-field">
			<div className="FormInput floating-label">
				<input {...props} />
				<label htmlFor={props.id}>{props.label}</label>
			</div>
		</div>
	);
};

export default FormInput;
