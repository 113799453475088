import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, PageHeader, SortableLessonCard } from '../../components';
import { useMe } from '../../hooks/useMe';
import { useSortable } from '../../hooks/useSortable';
import { useUpdateClientProfile } from '../../hooks/useUpdateClientProfile';
import { useLessonOrderData } from '../../hooks/useLessonOrderData';

import './LessonOrder.scss';

const LessonOrderingTable = ({ initialLessons, lessonsSortedHandler }) => {
	const [sortableActiveState, sortableInactiveState, sortableCompletedState, dragEndHandler, buttonClickHandler] = useSortable(initialLessons);

	useEffect(() => {
		lessonsSortedHandler([...sortableActiveState.items, ...sortableInactiveState.items, ...sortableCompletedState.items]);
	}, [sortableActiveState.items, sortableInactiveState.items, sortableCompletedState.items, lessonsSortedHandler]);

	return (
		<DragDropContext onDragEnd={dragEndHandler}>
			<div className="lessons-group" id="active">
				<h2>Active</h2>
				<p>
					The lesson(s) here will appear on the client’s dashboard as lessons to complete.
				</p>
				<Droppable droppableId="active-lessons">
					{(providedDroppable) => (
						<div className="active-lessons" {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
							{sortableActiveState.items.map((el, idx) => {
								return (
									<Draggable key={idx} draggableId={`active_${idx}`} index={idx}>
										{(providedDraggable) => (
											<SortableLessonCard
												key={idx}
												moduleTitle={el.moduleTitle}
												lessonTitle={el.title}
												type="active"
												innerRef={providedDraggable.innerRef}
												clickHandler={buttonClickHandler}
												{...providedDraggable.draggableProps}
												{...providedDraggable.dragHandleProps}
											/>
										)}
									</Draggable>
								);
							})}
							{providedDroppable.placeholder}
						</div>
					)}
				</Droppable>
			</div>

			<div className="lessons-group" id="inactive">
				<h2>Inactive</h2>
				<p>
					The lesson(s) here will be hidden from the client’s but can be moved to the Active section to become visible.
				</p>
				<Droppable droppableId="inactive-lessons">
					{(providedDroppable) => (
						<div className="inactive-lessons" {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
							{sortableInactiveState.items.map((el, idx) => {
								return (
									<Draggable key={idx} draggableId={`inactive_${idx}`} index={idx}>
										{(providedDraggable) => (
											<SortableLessonCard
												key={idx}
												moduleTitle={el.moduleTitle}
												lessonTitle={el.title}
												type="inactive"
												innerRef={providedDraggable.innerRef}
												clickHandler={buttonClickHandler}
												{...providedDraggable.draggableProps}
												{...providedDraggable.dragHandleProps}
											/>
										)}
									</Draggable>
								);
							})}
							{providedDroppable.placeholder}
						</div>
					)}
				</Droppable>
			</div>

			<div className="lessons-group" id="completed">
				<h2>Completed</h2>
				<p>
					The lesson(s) here are those that the client has completed. The lesson(s) will no longer be present on the client’s dashboard but will be accessible on their Modules page at any time.
				</p>

				<Droppable droppableId="completed-lessons">
					{(providedDroppable) => (
						<div className="completed-lessons" {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
							{sortableCompletedState.items.map((el, idx) => {
								return (
									<Draggable key={idx} draggableId={`completed_${idx}`} index={idx}>
										{(providedDraggable) => (
											<SortableLessonCard
												key={idx}
												moduleTitle={el.moduleTitle}
												lessonTitle={el.title}
												type="complete"
												innerRef={providedDraggable.innerRef}
												clickHandler={buttonClickHandler}
												{...providedDraggable.draggableProps}
												{...providedDraggable.dragHandleProps}
											/>
										)}
									</Draggable>
								);
							})}
							{providedDroppable.placeholder}
						</div>
					)}
				</Droppable>
			</div>
		</DragDropContext>
	);
};

const LessonOrder = (props) => {
	const user = useMe();
	const history = useHistory();
	const updateClientMutation = useUpdateClientProfile(user.clients);
	const [lessonDataLoading, lessonOrderData] = useLessonOrderData(+props.match.params.clientId, user.profile.trained_modules);

	if (lessonDataLoading) return 'loading...';

	const { client, assigned, unassigned } = lessonOrderData;

	let lessonPool = [...assigned, ...unassigned];

	const lessonsSortedHandler = (newLessons) => {
		lessonPool = newLessons;
	};

	const saveChangesHandler = (ev) => {
		updateClientMutation.mutateAsync({ client: client, fields: { assignedLessons: lessonPool } }).then((response) => {
			history.push(`/my-clients/${client.id}`);
		});
	};

	const scrollToId = (elId) => {
		const targetEl = document.getElementById(elId);

		if (targetEl) {
			window.scrollTo({
				top: targetEl.offsetTop,
				behavior: 'smooth',
			});
		}
	};

	return (
		<div className="LessonOrder">
			<PageHeader title="Lesson Order" icon="user-group">
				<Button btnStyle="secondary" onClick={saveChangesHandler}>
					Save Changes
				</Button>
			</PageHeader>
			<div className="wrapper">
				<div className="LessonOrder__groups">
					<LessonOrderingTable initialLessons={lessonPool} lessonsSortedHandler={lessonsSortedHandler} />
				</div>
				<div className="LessonOrder__actions">
					<Button
						onClick={() => {
							scrollToId('inactive');
						}}
						btnStyle="secondary"
						withIcon="arrow-down">
						Jump to Inactive Lessons
					</Button>
					<Button
						onClick={() => {
							scrollToId('completed');
						}}
						btnStyle="secondary"
						withIcon="arrow-down">
						Jump to Completed Lessons
					</Button>
				</div>
			</div>
		</div>
	);
};

export default LessonOrder;
