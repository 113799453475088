import { Icon } from '..';
import './FormSelect.scss';

const FormSelect = ({ id, label, options, name, defaultValue }) => {
	return (
		<div className="form-field">
			<div className="FormSelect floating-label">
				<select id={id} name={name} defaultValue={defaultValue}>
					<option></option>
					{options.map((option, index) => (
						<option key={index} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
				<label htmlFor={id}>{label}</label>
				<Icon name="caret-down" />
			</div>
		</div>
	);
};

export default FormSelect;
